/* page-title start */
.page-title {
	&.pt-f{
		background-attachment: fixed;
		@media @mobile{
			background-attachment: scroll;
		}
	}
	.height(566);
	overflow: hidden;
	position: relative;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 50% 100%;
	@media @tablet-portrait, @mobile {
		height: 23rem;
		background-position: 50% 50%;
	}

	// &:before {
	// 	content: '';
	// 	.full;
	// 	background: fade(@black, 20%);
	// }
	&.bg-center{
		background-position: 100% 50%;
		background-repeat: no-repeat;
		background-size: cover;
	}
	.right-deco{
		position: absolute;
		right: 0;
		bottom: 0;
		.width(500);
		@media @mobile{
			.width(250);
		}
	  }
	.right-deco-2{
		position: absolute;
		right: 0;
		bottom: 0;
		.width(366);
		@media @mobile{
			.width(100);
		}
	  }
	.text {
		.pos-center;
		width:100%;
		.text-center;
		color:@white;
		.padding(160,0,0,0);
		.font-size(25);
		.line-height(35);
		z-index: 5;
		@media @tablet{
			.padding(50,0,0,0);
		}
		@media @mobile{
			.padding(40,0,0,0);
		}
		@media @mobile-xs {
			.font-size(18);
			.line-height(25);
		}
		.header-text-wrap{
			display: flex;
            justify-content: flex-start;
            flex-direction: column;
			@media @tablet,@mobile{
				justify-content: center;
			}
		  }
		h1 {
			.ttu;
			.MB;
			.font-size(40);
			.line-height(60);
			color: @white;
			.margin(0,0,8,0);
			@media @mobile-xs {
				.font-size(36);
				.line-height(40);
			}
        }
		p {
			margin: 0;
		}
	}
}
/* page-title end */



.board-section{
	.padding(85,40,10,50);
	@media @mobile{
		.padding(85,0,90,40);
	}
	.board-wrap{
		// display: flex;
		// justify-content: space-between;
		// flex-wrap: wrap;
		display: grid;
		grid-template-columns: repeat(4, 1fr);
	//	grid-template-rows: 22rem;
		grid-gap: 6rem 4rem;
		padding: 0 0 4rem;
		@media @tablet{
			grid-template-columns: repeat(3, 1fr);
		}
		@media @tablet-portrait{
			grid-template-columns: repeat(2, 1fr);
		}
		@media @mobile {
			grid-gap: 4rem;
			grid-template-columns: repeat(1, 1fr);
		}
		// @media @mobile-xs {
		// 	grid-template-columns: repeat(1, 1fr);
		// 	grid-template-rows: none;
		// }
		.item{
			border-radius: 0 0 0 1.25rem;
			background-color: @white;
		//	width:21%;
		//	min-height: 21.875rem;
			display: flex;
			flex-direction: column;
			position: relative;
			//margin-bottom: 6rem;
			box-shadow: 1.777px 16.907px 68px 0px rgba(0, 0, 0, 0.2);
			transition: all 0.3s ease-in-out;
		   // .width(280);
			@media @tablet{
			// width: 30%;
			// margin-bottom: 4rem;
			padding-bottom: 4rem;
			// height: unset;
			// min-height: unset;   
			}
			// @media @tablet-portrait{
			//  width: 40%; 
			//  }
			@media @mobile{
			// width: 90%;
			// margin-bottom: 5rem;
			height: unset;
			min-height: unset;
			padding-bottom: 4rem;
			}
			&:hover{
				background-color: @primary;
				color: @white;
					.main-text{
					   .name{
							 color: @white;
						}
					   p{
						color: @white;
					   }
						.location{
						color: @white;
						  &::before{
						  color: @white;
						  }
						}
						.time{
						color: @white;
						  &::before{
						  color: @white;
						  }
						}
					}
				.image-hold{
					.img{
						-webkit-transform: scale(1.1);
						-moz-transform: scale(1.05);
						-ms-transform: scale(1.05);
						-o-transform: scale(1.1);
						transform: scale(1.1);
						@media only screen and (min-width: 767.99px) and (max-width: 1199.98px), only screen and (max-width: 767.98px) {
						transform: scale(1.05);
						}
					}
				}
				}
			.image-hold {
			 	.margin(-30,36,22,-36);
				position: relative;
				.height(350);
				width: 100%;
				border-radius: 0 0 0 1.25rem;
				box-shadow: -2.034px 12.84px 27px 0px rgba(0, 0, 0, 0.3);
				&:focus{
					outline: none;
				}
				@media @mobile{
					.height(450);
				}
			 .image {
				height: 100%;
				position: relative;
				overflow: hidden;
				// width: 100%;
				border-radius: 0 0 0 1.25rem;
			//	box-shadow: -2.034px 12.84px 27px 0px rgba(0, 0, 0, 0.3);
				&:focus{
					outline: none;
				}
				.img {
					display: block;
					height: 100%;
					background-size: cover;
					background-position: 50% 0%;
					background-repeat: no-repeat;
					transition: all 0.3s ease;
					&:focus{
						outline: none;
					}
				   }
			 	}
			}
			// &:hover{
			// 	.image-hold{
			// 		.img{
			// 			transform: scale(1.1);
			// 			@media only screen and (min-width: 767.99px) and (max-width: 1199.98px), only screen and (max-width: 767.98px) {
			// 			transform: scale(1.05);
			// 			}
			// 		}
			// 	}
			// }
			.main-text{
			.padding(0,35,20,25);
			position: relative;
			text-align: left;
			.mCSB_container{
				margin: 0;
			}
			.mCSB_scrollTools{
				right: -1rem;
			}
			.mCSB_scrollTools .mCSB_draggerContainer {
				right: unset;
			}
			.name{
			   text-align: left;
				.font-size(18);
				.MB;
			   color: @black;
				.line-height(28);
				margin-bottom: 0.5rem;
				text-transform: none;
			}
			.position{
				.font-size(14);
				color: @black;
				.MR;
				.line-height(28);
				margin-bottom: 0.6rem;
			}
			p{
				.font-size(14);
				color: @black;
				.MR;
				.line-height(28);
				margin-bottom: 0.6rem;
			}
			.card-text-scroll{
				max-height: 5.2rem;
			}
		}
	   }
	}
	.btn-wrap{
		text-align: center;
		margin: 0 auto;
		@media @mobile{
			margin-left: -2.5rem;
		}
	}
}
.donate-section{
	.padding(80,0,0,0);
	text-align: center;
	@media @mobile{
		.padding(80,0,80,0);
	}

	.donate-wrap{
		z-index: 1;
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		grid-gap: 5.31rem 3.5rem ;
		padding: 0 0 3.45rem;

		@media @tablet-portrait{
			grid-template-columns: repeat(2, 1fr);
		}
		@media @mobile {
			grid-gap: 4rem;
			grid-template-columns: repeat(2, 1fr);
		}
		@media @mobile-xs{
			grid-gap: 4rem;
			grid-template-columns: repeat(1, 1fr);
		}
		.donate-item{
			z-index: 1;
			position: relative;

			display: flex;
			.padding(46,35,0,35);
			align-items: center;
			flex-direction: column;
			border-radius: 1.25rem;
			background-color: rgb(255, 255, 255);
			box-shadow: 1.777px 0.75rem 4.25rem 0px rgba(0, 0, 0, 0.2);
			min-height: 16.375rem;
			.price{
				.MR;
				color:@primary;
				.font-size(36);
				margin-bottom: 1rem;
			}
			.text{
				.MB;
				color: @black;
				.font-size(16);
				.line-height(26);
				min-height:2.875rem;
				margin-bottom: 1.25rem;
			}
			.cards{
				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;
				img{
					.height(15);
					.margin(0,2,0,2);
				}
			}
			.btn-wrap{
				.pos-centerX;
				position: absolute;
				bottom: -1.5625rem;
				.btn-outline-white{
					border: 0.25rem solid white;
					min-width: 11.75rem;
					&:hover{
						transform: scale(1.1);
					}
				}
			}
		}
	}
	.shopping-wrap{
		z-index: 1;
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		grid-gap: 5.31rem 3.5rem ;
		padding: 0 0 3.45rem;
		@media @tablet{
			grid-template-columns: repeat(3, 1fr);
		}
		@media @tablet-portrait{
			grid-template-columns: repeat(2, 1fr);
		}
		@media @mobile {
			grid-gap: 4rem;
			grid-template-columns: repeat(1, 1fr);
		}

		.donate-item{
			z-index: 1;
			position: relative;
			display: flex;
			.padding(26,20,50,20);
			align-items: center;
			flex-direction: column;
			border-radius: 1.25rem;
			background-color: rgb(255, 255, 255);
			box-shadow: 1.777px 0.75rem 4.25rem 0px rgba(0, 0, 0, 0.2);
			.transition(all 0.4s ease);
			&:hover{
				.btn-wrap {
					width: 100%;
					.transition(all 0.4s ease);

					.btn-outline-white {
						width: 100%;
						.transition(all 0.4s ease);
					}
				}
			}
			.item-img{
				.width(155);
				.height(200);
				background-size: contain;
				background-position: center;
				background-repeat: no-repeat;
				margin-bottom: 0.75rem;
			}
			.price{
				.MR;
				color:@primary;
				.font-size(18);
				.line-height(36);
				//margin-bottom: 1rem;
			}
			.text{
				.MB;
				color: @black;
				.font-size(16);
				.line-height(26);
				margin-bottom: .5rem;
			}
			.cards{
				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;
				img{
					.height(15);
					.margin(0,2,0,2);
				}
			}
			.btn-wrap{
				.pos-centerX;
				position: absolute;
				bottom: -1.5625rem;
				.transition(all 0.4s ease);

				.btn-outline-white{
					border: 0.375rem solid white;
					min-width: 11.875rem;
					.line-height(50);
					padding: 0;
					border-radius: 2.5635rem;
					.transition(all 0.4s ease);

					span{
						padding: 0 1.5rem;
						.transition(all 0.3s ease);
					}
				}
			}
			.form-group {
				margin-bottom: 0;
				min-height: 2rem;
				position: relative;
				.form-control,
				.custom-select {
					.height(36);
					background: @white;
					border:1px solid #d2d2d2;
					border-radius: 0.25rem;
					color:@primary;
					.font-size(15);
					transition:  all .3s linear;
					.padding(0,15,0,15);
					text-align: center;
					-webkit-appearance:  none;
					//position: relative;
					&:focus{
						border:1px dashed @primary;
						box-shadow: none;
					}
					&:active{
						border:1px dashed @primary;
						box-shadow: none;
					}
					&::placeholder{
						.font-size(15);
						color: @primary;
						.MR;
						transition: 0.3s ease linear;
					}
				}

			}
		}
	}

}

.view-more-link{
	display: inline-block;
	.ttu;
	.MB;
	.font-size(16);
	.line-height(30);
	color:@primary;
	text-decoration: underline;
	&:hover{
		text-decoration: none;
	}
}

.shopping-page{
	.padding(217,0,87,0);
	@media @tablet{
		.padding(135,0,87,0);
	}
	@media @mobile{
		.padding(100,0,87,0);
	}
	h3{
		.font-size(25);
		text-align: left;
		margin-bottom: 1.5rem;
	}
	.btns-wrap{
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-top: 2.75rem;
			@media @mobile-xs {
				flex-wrap: wrap;
			}
		.btn{
			min-width:16.625rem;
			@media @mobile-xs{
				width: 100%;
				margin-bottom: 1rem;
				min-width: 100%;
			}
		}
	}
	.box{
		border-radius: 1.25rem;
		background-color: rgb(255, 255, 255);
		box-shadow: 1.777px 16.907px 68px 0px rgba(0, 0, 0, 0.2);
		.padding(35,32,25,32);
		z-index: 10;
		@media @mobile-xs{
			.padding(35,12,35,12);
		}
	}
	.checkout-blocks-wrap{
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		.ckeckout-form-block{
			position: relative;
			max-width: calc(100% - 28rem);
			width: 100%;
			z-index: 160;
			@media @tablet-portrait,@mobile{
				max-width: 100%;
				width: 100%;
			}
			.cards{
				display: flex;
				justify-content: flex-end;
				align-items: center;
				margin-bottom: 2.75rem;
				img{
					.height(22);
					.margin(0,0,0,7);
				}
			}
			.toggle-ttl{
				border-bottom: 2px solid @primary;
				.padding(6,0,6,0);
				margin-bottom: 3rem;
				display: flex;
				align-items: center;
				justify-content: space-between;
				&:hover{
					text-decoration: none;
				}
				&.collapsed{
					i{
						transform: rotate(0);
					}
				}
				i{
					color: @primary;
					.font-size(18);
					transform: rotate(-180deg);
					transition: all .5s;
				}
				span{
					.font-size(24);
					.ttu;
					font-weight: 700;
					color: @black;
				}
			}
			.form-group{
				margin-bottom: 2.25rem;
				.form-control,
				.custom-select{
					.height(44);
					.font-size(15);
					&:focus{
						border:1px solid @primary;
						box-shadow: none;
					}
					&::placeholder{
						.font-size(15);
					}
				}
				.floating-label{
					position:  absolute;
					opacity:  0;
					transition:  all .3s ease;
					top:-2.5rem;
					left: 0;
					z-index: 0;
					color: #8a8989;
					.line-height(12);
					.font-size(12);
					font-weight: 700;
					margin: 0;
				}
				input:not(:placeholder-shown) + .floating-label {
					transform:  translateY(20px);
					opacity:  1;
					z-index: 1;
				}
				textarea:not(:placeholder-shown) + .floating-label{
					transform:  translateY(20px);
					opacity:  1;
				}
				select:not(:placeholder-shown) + .floating-label{
					transform:  translateY(20px);
					opacity:  1;
				}
			}
			.total-block{

				.total{
					.font-size(18);
					margin-bottom: 2rem;
				}
				.btn{
					min-width: 16.625rem;
					position: relative;
					.pp-icon{
						position: absolute;
						.pos-centerY;
						left: 1rem;
						.width(20);
					}
				}

			}
		}
		.summary-block-wrap{
			.width(360);
			@media @tablet-portrait,@mobile{
				width: 100%;
				margin-top: 2.5rem;
			}
			.small-cart-table{
				tbody{
					tr{
						td{
							.font-size(14);
							.line-height(20);
							border: 0;
							border-bottom: 1px solid rgb(210, 210, 210);
							small{
								.font-size(12);
							}
						}

					}
				}
			}
			.summary-block{
				border-radius: 1.25rem;
				background-color: rgb(255, 255, 255);
				box-shadow: 1.777px 16.907px 68px 0px rgba(0, 0, 0, 0.2);
				width: 100%;
				.padding(28,17,25,17);
				.ttl{
					margin: 0;
					color: @black;
					font-weight: 700;
					.font-size(18);
					margin-bottom: 0.375rem;
					.ttu;
					.products-ammount{
						text-transform: none;
					}
				}

				.my-custom-scrollbar {
					position: relative;
					max-height:14.5rem;
					overflow-y: auto;
					overflow-x: hidden;
				}
				.table-wrapper-scroll-y {
					display: block;
				}
				.promo{
					margin-top: 0.5rem;
				}
				.promocode-input{

					width: 100%;
					border-radius: 0.25rem;
					border: 1px solid rgb(210, 210, 210);
					background-color: @white;
					position: relative;

					input{
						.font-size(14);
						font-weight: 400;
						width: 100%;
						border: 0;
						.height(38);
						padding: 0 .75rem;
						&::placeholder{
							color: rgb(193, 193, 193);
						}
					}
					button {
						background-color: @primary;
						border-radius: 0.25rem;
						color: @white;
						.ttu;
						.font-size(14);
						.width(72);
						cursor: pointer;
						position: absolute;
						.pos-centerY;
						border:1px solid rgb(0, 0, 0);
						right: 0.125rem;
						.height(34);
					}
				}
				.wrap{
					display: flex;
					justify-content: space-between;
					&.total-wrap{
						border-top: 2px solid @primary;
						padding-top:0.625rem;
						margin-top: 1.35rem;
						p{
							.font-size(18);
							font-weight: 700;
							.ttu;
						}
					}
					p{
						color: @black;
						.font-size(15);
						margin: 0;
						.line-height(25);
					}
				}
			}
		}

	}

}

.shopping-cart-table{
	margin-bottom: 0;
	&:focus{
		outline: none;
	}
	tr{
		@media @mobile{
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			position: relative;
			align-items: center;
			border-bottom: 1px solid rgb(210, 210, 210);
		}
		&.total-sum{
			border: 0;
			td{
				border: 0;
			}
			.total{
				font-weight: 700;
				.width(255);
				margin-left: auto;
				.ttu;
				.font-size(18);
				text-align: left;
				label{
					.width(80);
					color: @black;
					.ttu;
					.font-size(18);
					.line-height(22);
				}
			}
		}
		&.total-order-info{
			border: 0;
			border-top: 2px solid @primary;
			td{
				border: 0;
					font-weight: 700;

					margin-left: auto;
					.ttu;
					.font-size(18);
					text-align: left;
				@media @mobile {
					&:first-child{
						display: none;
						width: 0;
					}
					&:nth-child(2) {
						width: 50%;
						padding: 1rem 0.5rem;
					}

					&:nth-child(3) {
						width: 50%;
						padding: 1rem 0.5rem;
						text-align: right!important;
					}
				}
			}
		}
		th {
			border: 0;
			background-color: @primary;
			vertical-align: middle;
			color: @white;
			.line-height(26);
			.font-size(14);
			padding: 0.5rem;
			white-space: nowrap;
			text-align: center;
			&:nth-child(1) {
				text-align:left;
			}
			&:nth-child(2) {
				text-align:left;
			}
			@media @mobile{
				display: none;
			}
		}
		td {
			border: 0;
			border-bottom: 1px solid rgb(210, 210, 210);
			vertical-align: middle;
			text-align: center;
			.line-height(24);
			.font-size(15);
			color: #212121;
			@media @mobile{
				border:0;
				&:before {
					content: attr(data-label);
					display: block;
					font-weight: 400;
					margin: 0 0 0.3rem;
				}
			}
			&:nth-child(1) {
				.width(90);
				@media @mobile-xs{
					padding-left: 0;
				}
			}
			&:nth-child(2) {
				text-align:left;
				@media @mobile{
					width: calc(100% - 6.25rem);
				}
				@media @mobile-xs{
					padding-left: 0;
					width: calc(100% - 6.25rem - 6rem);
				}
			}
			&:nth-child(3) {
				@media @mobile{
				 .width(100);
					padding: 1.5rem 0.5rem 0 0.5rem;
				}
				@media @mobile-xs{

				 .width(90);
					padding: 0rem 0.5rem 0 0;
				}
			}
			&:nth-child(4) {
				@media @mobile {
					.width(120);
				}
				@media @mobile-xs {
					padding-left: 0;
					width: 5.5rem;
				}
			}
			&:nth-child(5){
				@media @mobile{
					width: calc((100% - 15rem) / 2);
				}
				@media @mobile-xs{
					width: calc((100% - 5.55rem) / 2);
					padding-left: 0;
				}
			}
			&:nth-child(6){
				@media @mobile{
					width: calc((100% - 15rem) / 2);
					text-align: right!important;
				}
				@media @mobile-xs{
					width: calc((100% - 5.55rem) / 2);
					text-align: right!important;
					padding-left: 0;
				}
			}
			p {
				margin: 0;
			}
			.prod-img{
				.width(56);
				.height(73);
				margin: 0 auto;
				background-size: contain;
				background-repeat: no-repeat;
				background-position: center;
			}
			.ttl{
				.margin(0,0,5,0);
				.font-size(16);
				font-weight: 700;
			}
			.delete{
				width: 0.75rem;
				height: 0.75rem;
				color: #a2a2a2;
				@media @mobile{
					position: absolute;
					top:0.25rem;
					right:0.5rem;
				}

			}
			.promocode-input{
				.width(255);
				margin-left: auto;
				border-radius: 0.25rem;
				border: 1px solid rgb(210, 210, 210);
				background-color: @white;
				position: relative;
				@media @mobile-xs{
					max-width: 100%;
					width: 100%;
				}
				input{
					.font-size(14);
					font-weight: 400;
					width: 100%;
					border: 0;
					.height(38);
					padding: 0 .75rem;
					&::placeholder{
						color: rgb(193, 193, 193);
					}
				}
				button {
					background-color: @primary;
					border-radius: 0.25rem;
					color: @white;
					.ttu;
					.font-size(14);
					.width(72);
					cursor: pointer;
					position: absolute;
					.pos-centerY;
					border:1px solid rgb(0, 0, 0);
					right: 0.125rem;
					.height(34);
				}
			}
			&.promo {

				td {
					border:0;
					padding-top: 1rem;
					padding-bottom: 1rem;
					text-align: left;
					@media @mobile-xs {
						width:100%;
					}
					&:nth-child(1) {
						@media @mobile-xs {
							display: none;
						}
					}
				}
			}
			&.item-name{
				min-width: 12rem;
				font-weight: 700;
				@media @mobile-xs{
					min-width: auto;
				}
			}
			.form-group {
				margin: 0 auto;
				margin-bottom: 0;
				min-height: auto;
				position: relative;
				.form-control,
				.custom-select {
					.height(36);
					color:#212121;
					.font-size(15);
					.padding(0,10,0,10);

				}
				.custom-select {
					.width(70);
					background: #ffffff url(../img/svg/chevron-down-solid.svg) no-repeat right 0.5rem center;
					background-size: 0.75rem 0.5rem;
				}
			}
			.sub,
			.ship{
				.font-size(15);
				.line-height(22);
				color: #474747;
				.width(255);
				margin-left: auto;
				text-align: left;
				@media @mobile-xs{
					width: 100%;
				}
				label{
					.width(80);
					color: @black;
					.font-size(15);
					.line-height(22);
				}
			}
		}

	}

	.bottom-underline{
		border-bottom: 2px solid @primary;

		td{
			.line-height(25);
			.font-size(16);
			&:nth-child(1) {

				@media @mobile{
					width: 100%;
				}
			}

		}
		.promo{
			@media @mobile-xs{
				width: 100%;
			}
		}
	}

}
.order-info-wrap{
	display: flex;
	flex-wrap: wrap;
	.order-table-wrap{
		width: calc(100% - 15.5rem);
		margin-bottom: 2rem;
		@media @mobile{
			width: 100%;

		}
		.ttl{
			padding-left: 6.25rem;
		}
		.shopping-cart-table{
			tr{
				td {

					&:nth-child(1) {
						.width(90);
						@media @mobile-xs{
							padding-left: 0;
						}
					}
					&:nth-child(2) {
						text-align:left;
						@media @mobile{
							width: calc(100% - 6.25rem - 12rem);
						}
						@media @mobile-xs{
							padding-left: 0;
							width: calc(100% - 6.25rem);
						}
					}
					&:nth-child(3) {
						@media @mobile{
							width: 6rem;
							padding: 1.5rem 0.5rem 0 0.5rem;
						}
						@media @mobile-xs{
							width: 50%;
							padding: 0rem 0.5rem 0 1rem;
						}
					}
					&:nth-child(4) {
						@media @mobile {
							width: 6rem;
						}
						@media @mobile-xs {
							width: 50%;
							padding-left: 0;
							text-align: right!important;
						}
					}
				}
			}
		}
	}
	.ship-block-wrap{
		width: 15.5rem;
		display: flex;
		flex-direction: column;
		@media @mobile{
			width: 100%;
		}
		.adress{
			border-left: 1px solid rgb(210, 210, 210);
			flex-grow: 1;
			.font-size(15);
			color: @black;
			.padding(24,15,15,32);
			@media @mobile{
				border: 0;
				margin-bottom: 1rem;
			}
			p{
				.font-size(15);
				.line-height(30);
				color: @black;
				margin-bottom: 1rem;
			}
			.pp{
				.width(74);
			}

		}
	}
	.ttl{
		background-color: @primary;
		color: @white;
		.font-size(14);
		text-transform: none;
		font-weight: 700;
		line-height: 1.429;
		padding: 0.625rem 0 0.625rem 2rem;
		margin-bottom: 0;
	}

}
.thanks-ttl{
	.font-size(30);
	color: rgb(35, 75, 141);
	font-weight: 700;
	text-transform: none;
	margin-bottom: 1rem;
}


.section-app-1{
	.padding(77,0,230,0);
	background-size: 100% auto;
	background-position: 50% 100%;
	background-repeat: no-repeat;
	@media @tablet-portrait{
		.padding(70,0,120,0);
	}
	@media @mobile{
		.padding(70,0,120,0);
	}
	h3{
		margin-bottom: 4.5rem;
		@media @mobile{
			margin-bottom: 2rem;
		}
	}
	.image-side{
		position: relative;
		.width(450);
		.height(450);
		border-radius: 50%;
		border: 2px solid @primary;
		@media @tablet,@mobile{
			margin: 0 auto;
		}
		@media @mobile-xs{
			.width(340);
			.height(340);
		}
		.car{
			position: absolute;
			.width(545);
			top:0.5rem;
			height: auto;
			.left(-95);
			@media @mobile-xs{
				.width(370);
				top:1rem;
				.left(-45);
			}
		}
		.logo{
			position: absolute;
			.width(130);
			.height(130);
			border-radius: 50%;
			border: 0.625rem solid @white;
			background-size: cover;
			background-position: center;
			background-repeat: no-repeat;
			right: 0;
			top:0;
		}
	}
	.text{
		padding-top: 3.75rem;
		padding-right: 4rem;
		@media @mobile{
			padding-right: 0;
		}
		p{
			margin-bottom: 2.5rem;
		}
	}
}

.aventura-residents-section{
	.padding(72,0,40,0);
	.aventura-residents-inner-wrap{
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
	}
	h3{
		margin-bottom: 2rem;
		position: relative;
		span{
			background-color: white;
			.padding(0,15,0,15);
			position: relative;
			z-index: 2;
			@media @mobile{
				display: block;
				margin: 0 auto;
				max-width: 70%;
			}
		}

		.line{
			.pos-centerY;
			background-color: rgb(219, 55, 50);
			width: 45%;
			height: 3px;
			&.line-left{
				left: 0;
			}
			&.line-right{
				right: 0;
			}
		}
	}
	p{
		//text-align: center;
		margin: 0 auto;
		//max-width:62.5rem;
		margin-bottom: 1.25rem;
		@media @mobile{
			text-align: center;
		}
		&.blue,
		.blue{
			color:@primary;
		}
	}
	.text-content{
		width: calc(100% - 270px);
		padding-right: 5rem;
		@media @mobile{
			width: 100%;
			padding-right: 0;
			margin-bottom: 2rem;
		}
	}
}

.community-panel-section{
	.padding(32,0,40,0);
	h3{
		margin-bottom: 2rem;
		position: relative;
		span{
			background-color: white;
			.padding(0,15,0,15);
			position: relative;
			z-index: 2;
			@media @mobile{
				display: block;
				margin: 0 auto;
				max-width: 70%;
			}
		}

		.line{
			.pos-centerY;
			background-color: rgb(219, 55, 50);
			width: 45%;
			height: 3px;
			&.line-left{
				left: 0;
			}
			&.line-right{
				right: 0;
			}
		}
	}
	p{
		text-align: center;
		margin: 0 auto;
		max-width:62.5rem;
		margin-bottom: 1.25rem;
		&.blue,
		.blue{
			color:@primary;
		}
	}
	.community-wrap{
		display: grid;
		grid-template-columns: repeat(6, 1fr);
		grid-gap: 1rem 0.625rem;
		padding: 0 0 4rem;
		@media @tablet{
			grid-template-columns: repeat(3, 1fr);
		}
		@media @mobile {
			grid-template-columns: repeat(2, 1fr);
		}

		.item{
			overflow: hidden;
			background-color: @white;
			display: flex;
			flex-direction: column;
			position: relative;
			transition: all 0.3s ease-in-out;
			&:hover{
				.image-hold{
					.img{
						transform: scale(1.05);
					}
				}
			}
			.image-hold {
				position: relative;
				width: 100%;
				padding-bottom: 156%;
				overflow: hidden;
				margin-bottom: 0.625rem;
				.img{
					background-color: @primary;
					background-size: cover;
					background-position: center;
					background-repeat: no-repeat;
					width: 100%;
					height: 100%;
					position: absolute;
				}
			}
			p{
				text-align: center;
				margin-bottom: 0rem;
			}
		}
	}
}

.separation-section{
	background-color: @primary;
	.padding(20,10,20,10);
	.separation-block{
		display: flex;
		align-items: center;
		@media @mobile-xs{
			flex-direction: column;
		}
		.pic{
			.width(150);
			@media @mobile{
				.width(130);
				margin-bottom: 1rem;
			}
		}
		.text{
			.padding(0,100,0,50);
			.font-size(30);
			color: rgb(255, 255, 255);
			font-weight: 700;
			line-height: 2.5rem;
			text-align: center;
			margin-bottom: 0;
			@media @mobile{
				.font-size(26);
				.padding(0,20,0,20);
				line-height: 2.25rem;
			}
			@media @mobile-xs{
				.font-size(23);
				.padding(0,20,0,20);
				line-height: 2rem;
			}
		}

	}
}

.apd-list-section{
	.padding(49,0,90,0);
	.list {
		max-width: 53rem;
		margin:0 auto;
		.item {
			display: flex;
			justify-content: flex-start;
			align-items: flex-start;
			margin-bottom: 0.625rem;
			@media @mobile-xs {
				flex-direction: column;
				align-items: center;
				.padding(40,0,0,0);
			}
			.avatar {
				.width(98);
				.height(98);
				border-radius: 50%;
				flex-shrink: 0;
				.margin(0,40,0,0);
				position: relative;
				background-color: @primary;
				display: flex;
				justify-content: center;
				align-items: center;
				box-shadow: -2.034px 12.84px 27px 0px rgba(0, 0, 0, 0.3);
				@media @mobile-xs{
					margin: 0 auto;
				}
				.pic-inner{
					border: 2px solid @white;
					display: flex;
					justify-content: center;
					align-items: center;
					border-radius: 50%;
					background-color: rgb(219, 55, 50);
					width: 83%;
					height: 83%;
					img{
						width: 52%;
					}
				}
			}
			.hold{
				padding-top: 2.5rem;
			}
			h2 {
				color: @black;
				font-weight: 700;
				.font-size(16);
				.line-height(22);
				margin: 0;
				text-transform: none;
				margin-bottom: 0.625rem;
			}

			p {
				.margin(0,0,10,0);
			}
		}
	}
}

.social-section{
	.padding(0,0,94,0);
	.social-holder{
		display: flex;
		justify-content: center;
		margin: 0 auto;
		@media @mobile{
			flex-wrap: wrap;
		}
		.social-box{
			width: 270px;
			margin: 0 1rem;
			.height(450);
			overflow: hidden;
			border: 1px solid rgb(234, 234, 234);
			@media @mobile{
				margin: 0 auto;
				margin-bottom: 1rem;
			}
		}
		.title{
			background-color: @primary;
			color: @white;
			.padding(15,18,15,18);
			&.insta{
				background-image: -moz-linear-gradient( 90deg, rgb(219,55,50) 1%, rgb(35,75,141) 100%);
				background-image: -webkit-linear-gradient( 90eg, rgb(219,55,50) 1%, rgb(35,75,141) 100%);
				background-image: -ms-linear-gradient( 90deg, rgb(219,55,50) 1%, rgb(35,75,141) 100%);
				background-image: linear-gradient( 90deg, rgb(219,55,50) 1%, rgb(35,75,141) 100%);

			}
			h5{
				.font-size(15);
				.line-height(20);
				.ttu;
				color: rgb(255, 255, 255);
				font-weight: 700;
				margin-bottom: 0;
				i{
					margin-right: 0.5rem;
				}
			}
		}
	}
}

.social-holder{
	display: flex;
	justify-content: center;
	margin: 0 auto;
	@media @mobile{
		flex-wrap: wrap;
	}
	.social-box{
		width: 270px;

		.height(450);
		overflow: hidden;
		border: 1px solid rgb(234, 234, 234);
		@media @mobile{
			margin: 0 auto;
			margin-bottom: 1rem;
		}
	}
	.title{
		background-color: @primary;
		color: @white;
		.padding(15,18,15,18);
		&.insta{
			background-image: -moz-linear-gradient( 90deg, rgb(219,55,50) 1%, rgb(35,75,141) 100%);
			background-image: -webkit-linear-gradient( 90eg, rgb(219,55,50) 1%, rgb(35,75,141) 100%);
			background-image: -ms-linear-gradient( 90deg, rgb(219,55,50) 1%, rgb(35,75,141) 100%);
			background-image: linear-gradient( 90deg, rgb(219,55,50) 1%, rgb(35,75,141) 100%);

		}
		h5{
			.font-size(15);
			.line-height(20);
			.ttu;
			color: rgb(255, 255, 255);
			font-weight: 700;
			margin-bottom: 0;
			i{
				margin-right: 0.5rem;
			}
		}
	}
}