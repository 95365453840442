.modal-content {  
    max-width:100%;
    box-shadow: 1.777px 16.907px 68px 0px rgba(0, 0, 0, 0.2);
    border-radius: 1.25rem 0 1.25rem 1.25rem;
    background-color: #ffffff;
    @media @mobile{
        .margin(30,0,30,0);
    }
    .modal-header{
    border:0;
    .padding(0,0,0,0);
    .modal-title{
      margin: 0;
      color: #000000;
      .font-size(26);
      font-weight: 700;
    }
    .close {
      position: absolute;
      top: 2.7rem;
      right: 2rem;
      width: 1.8rem;
      height: 1.8rem;
      overflow: hidden;
      text-indent: -999px;
      background: url(../img/close.png) no-repeat 50% 50%;
      background-size: contain;
      padding: 0;
      opacity: 1;
      margin-bottom: -0.81rem;
      z-index: 3;
    }
    }
  }


  .modal-info{
    .modal-dialog{
      max-width: 56.68rem;
      margin: 0 auto;
      @media @mobile{
        max-width: 90%;
      }
    }
    .modal-content { 
      .modal-header{
      border:0;
      .padding(0,29,0,40);
      text-align: center;
      justify-content: center;
      @media @mobile{
        .padding(46,30,0,30);
      }
    }
    .modal-body{
        padding-bottom: 2.2rem;
        .wrap{
            display: flex;
            justify-content: space-between;
            @media @tablet,@mobile{
                flex-direction: column;
            }
           
            .image-hold {
                .margin(-36,47,22,27);
                min-width: 17.31rem;
                position: relative;
               .height(350);
               width: 100%;
               border-radius: 0 0 0 1.25rem;
               box-shadow: -2.034px 12.84px 27px 0px rgba(0, 0, 0, 0.3);
                @media @tablet{
                    .height(350);
                    width: 17.31rem;
                    margin: 0 auto;
                }
                   @media @mobile{
                       .height(350);
                       min-width: 80%;
                       width: 80%;
                   }
                  
                .image {
                    height: 100%;
                    position: relative;
                    overflow: hidden;
                    width: 100%;
                    border-radius: 0 0 0 1.25rem;
                   .img {
                         border-radius: 0 0 0 1.25rem;
                         height: 100%;
                         width: 100%;
                         background-size: cover;
                         background-position: 50% 0%;
                         background-repeat: no-repeat;
                         transition: all 0.3s ease;
                         display: block;
                         
                      }
                    }
               }
        }
        .right-side{
            .padding(40,0,0,0);
        .name{
            .MB;
            .font-size(22);
            .line-height(26);
            color: @black;
            margin: 0;
        }
        .position{
            .MR;
            .font-size(14);
            .line-height(30);
        }
        .modal-text{
           .height(469);
           @media @mobile {
               height:auto;
           }
        }
    }
    }
  }
  }