// news-card
.news-section{
  .padding(72,0,42,0);
  @media @mobile{
    .padding(72,0,92,0);
  }
    .customise-tabs{
        .nav-tabs-wrap{
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            .nav-tabs {
               border: 0;
               .nav-item{
         .padding(6,16,0,16);
         .nav-link{
            border: 0;
            border-radius: 0;
            padding: 0;
            .MR;
            .font-size(16);
            color: @primary;
            .ttu;
            position: relative;
            &::before{
            content: "";
            position: absolute;
            .pos-centerX;
            width: 0;
            height: 2px;
            background-color: @secondary;
            transition: all 0.3s ease-in-out;
            bottom: -0.5rem;
            }
            &:focus{
            border: 0;
            outline: 1px dashed @secondary;
            }
            &:hover{
            &::before{
            border: 0;
            outline: none;
            width: 100%;
            }
            }
            &.active{
            .MB;
            border: 0;
                  &::before{
            width: 100%;
            }
            }
                  }
               }
            }
         }
        .tab-content{
           .padding(62,0,0,0);
           @media @mobile{
            .padding(62,20,0,25); 
           }
           .events-wrap{
              // display: flex;
              // justify-content: space-between;
              // align-items: stretch;
              // flex-wrap: wrap;
              display: grid;
              grid-template-columns: repeat(4, 1fr);
              grid-gap: 4.6875rem 3.5rem;
              padding: 0 0 4rem;
              @media @tablet{
                grid-template-columns: repeat(3, 1fr);
              }
              @media @tablet-portrait,@mobile{
                grid-gap: 4.6875rem 4.5rem;
                grid-template-columns: repeat(2, 1fr);
              }
              @media @mobile-xs{
                grid-gap: 5rem;
                grid-template-columns: repeat(1, 1fr);
              }
           }
        }
	   }

      .item{
        border-radius: 0 0 0 1.25rem;
        background-color: @white;
      //  width:22%;
        min-height: 21.875rem;
        display: flex;
        flex-direction: column;
        position: relative;
     //   margin-bottom: 4.6875rem;
        box-shadow: 1.777px 16.907px 68px 0px rgba(0, 0, 0, 0.2);
        transition: all 0.3s ease-in-out;
       // .width(280);
        @media @tablet{
     //   width: 30%;
     //   margin-bottom: 4rem;
        height: unset;
        min-height: unset;   
        }
        @media @tablet-portrait{
    //     width: 40%; 
         }
        @media @mobile{
    //    width: 100%;
      //  margin-bottom: 5rem;
        height: unset;
        min-height: unset;
        &:last-child{
        margin-bottom: 1rem;
        }
        }
        
        .image-hold {
         .margin(-11,36,22,-36);
         position: relative;
        // .height(172);
         width: 100%;
       //  height: 100%;
         border-radius: 0 0 0 1.25rem;
         box-shadow: -2.034px 12.84px 27px 0px rgba(0, 0, 0, 0.3);
         .date{
         position: absolute;
         top: 0;
         left: 0;
         .MR;
         .font-size(13);
         .line-height(26);
         .padding(4,15,4,15);
         color: @primary;
         background-color: @secondary;
         z-index: 2;
         }
         .image {
         height: 100%;
         position: relative;
         overflow: hidden;
         width: 100%;
         border-radius: 0 0 0 1.25rem;
               .img {
                  border-radius: 0 0 0 1.25rem;
                  height: 100%;
                  width: 100%;
                  padding-bottom: 61%;
                  background-size: cover;
                  background-position: 50% 50%;
                  background-repeat: no-repeat;
                  transition: all 0.3s ease;
                  display: block;
                  
                  @media @tablet,@mobile{
                    padding-bottom: 56%;
                 }
               }
         }
        }
        &:hover{
        background-color: @primary;
        color: @white;
            .main-text{
               .card-ttl{
                   a{
                     color: @white;
                   }
                }
               p{
                color: @white;
               }
                .location{
                color: @white;
                  &::before{
                  color: @white;
                  }
                }
                .time{
                color: @white;
                  &::before{
                  color: @white;
                  }
                }
            }
        .image-hold{
            .img{
                transform: scale(1.1);
                @media only screen and (min-width: 767.99px) and (max-width: 1199.98px), only screen and (max-width: 767.98px) {
                transform: scale(1.05);
                }
            }
        }
        }
        .main-text{
        .padding(0,25,30,25);
        text-align: left;
        .card-ttl{
           text-align: left;
            .font-size(16);
            .MB;
           color: @black;
            .line-height(26);
            margin-bottom: 0.5rem;
            text-transform: none;
            a{
               .font-size(16);
            .MB;
           color: @black;
            }
        }
        p{
        .font-size(14);
        color: @black;
        .MR;
        .line-height(24);
        margin-bottom: 1rem;
        max-height: 5.6rem;
        overflow-y: hidden;
        }
        .location{
         .font-size(14);
         color: @black;
         .MR;
         .line-height(24);
         padding-left:1.25rem;
         position: relative;
            &::before{
            content: "\f3c5";
            position: absolute;
            top:0;
            left: 0;
            color: @primary;
            width: .8rem;
            height: auto;
            font-family: Font Awesome\ 5 Free;
            font-weight: 900;
            }
        }
        .time{
         .font-size(14);
         color: @black;
         .MR;
         .line-height(24);
         padding-left:1.25rem;
         position: relative;
            &::before{
            content: "\f017";
            position: absolute;
            top:0;
            left: 0;
            color: @primary;
            width: .7rem;
            height: auto;
            font-family: Font Awesome\ 5 Free;
            font-weight: 900;
            }
         }
        }
   }
   .search-form{
    width: 21rem;
      .padding(0,0,20,0);
      @media @mobile{
        .padding(30,0,20,0);
        width: 95%;
      }
      .search{
        position: relative;
        .search_input {
       
        height: 2.5rem;
        width: 100%;
        &:focus{
          outline: 1px dashed @primary;
          box-shadow: none;
        }
        }
      .submit {
        display: block;
        right: 0;
        padding: 0;
        position: absolute;
        bottom:  0;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-pack: center;
        justify-content: center;
        -ms-flex-align: center;
        align-items: center;
        width: 1.5rem;
        height:2.5rem;
        border: 0;
        background-color: transparent;
        &:focus{
          outline: 1px dashed @primary;
         box-shadow: none;
        }
        &:before {
          content: "\f002";
          position: absolute;
          .pos-centerY;
          right: 0.4rem;
          font-family: Font Awesome\ 5 Free;
          font-weight: 900;
          color: @primary;
        }
        .hide-content {
          border: 0;
          clip: rect(0 0 0 0);
          height: 1px;
          margin: -1px;
          overflow: hidden;
          padding: 0;
          position: absolute;
          width: 1px;
          }
        }
      }
   }
}
.news-inner-wrap{
   display: flex;
   justify-content: space-between;
   @media @tablet-portrait,@mobile{
     flex-wrap: wrap;
   }
   h3{
     .font-size(24);
     .line-height(34);
     font-weight: 700;
     .MR;
     color: @primary;
     margin-bottom: 2.1875rem;
   }
   .ref-link{
     color: @secondary;
     font-weight: 700;
   }
   .left-side{
     width: 70%;
     display: flex;
     flex-direction: column;
     padding-top: 5.81rem;
     @media @tablet-portrait,@mobile{
       width: 100%;
       margin-bottom: 2rem;
     }
     h3{
       .font-size(30);
       .line-height(40);
       .MB;
       color: @black;
       margin-bottom: .75rem;
       text-transform: none;
       text-align: left;
     }
     .news-date{
      .font-size(14);
      .line-height(30);
      .MR;
      color: @black;
      margin-bottom: 2rem;
     }
     .event-location{
      .font-size(14);
      color: @black;
      .MR;
      .line-height(36);
      padding-left:1.25rem;
      position: relative;
         &::before{
         content: "\f3c5";
         position: absolute;
         top:0;
         left: 0;
         color: @primary;
         width: .6rem;
         height: auto;
         font-family: Font Awesome\ 5 Free;
         font-weight: 900;
         }
     }
     .event-time{
      .font-size(14);
      color: @black;
      .MR;
      .line-height(36);
      padding-left:1.25rem;
      position: relative;
         &::before{
         content: "\f017";
         position: absolute;
         top:0;
         left: 0;
         color: @primary;
         width: .6rem;
         height: auto;
         font-family: Font Awesome\ 5 Free;
         font-weight: 900;
         }
      }
     p {
       .font-size(15);
       .MR;
       .line-height(30);
       color: #0e0e0e;
       margin-bottom: 1.625rem;
   }
     ul {
       list-style: none;
       margin: 0;
       margin-top: 0.625rem;
       li {
         .line-height(30);
         position: relative;
         .padding(0,0,20,0);
         .font-size(15);
         color:@text;
         font-weight: 400;
         text-align: left;
         @media @mobile-xs {
           width:100%;
         }
         a{
           color:@text;
           cursor: pointer;
           &:hover{
             text-decoration: none;
             color: @primary;
           }
         }
       }
     }
     img{
       margin: 0 auto;
       text-align: center;
       .width(630);
       display: block;
       max-width: 100%;
       height: auto;
        border-radius: 0;
       margin-bottom: 3rem;
       margin-top: 1.8125rem;
       @media @tablet-portrait,@mobile{
         width: 100%;
         max-width: 30rem;
       }
     }
     .new-wrap{
      max-width: 40rem;
      margin: 0 auto;
      margin-bottom: 2rem;
      width: 100%;
     }
     .video-holder{
      position: relative;
      padding-bottom: 56.25%;
      height: 0;
      width: 100%;
      iframe{
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
      }
    }
     .bottom-line{
       margin-top: 1.8rem;
       border-top:2px solid @primary;
       display: flex;
       justify-content: space-between;
       align-items: center;
       padding-top: 1.9rem;
       .social{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        .share{
          .font-size(14);
          .MB;
          .line-height(30);
          color: #0e0e0e;
          margin-right: 0.875rem;
        }
        a{
          color: @black;
          margin-right: 0.875rem;
          &:hover{
            text-decoration: none;
            transform: scale(1.1);
            color: @primary;
          }
          &:last-child{
            margin-right: 0;
          }
        }
       }
     }
   }
   .right-side{
     width: 22%;
     @media @tablet-portrait,@mobile{
       width: 100%;
       margin-bottom: 3rem;
     }
     .sidebar{
       position: relative;
       margin-top: 4.125rem;
       border-radius: 1.25rem 0px 1.25rem 1.25rem;
       background-color: @white;
       margin-left:auto;
      
       box-shadow: 1.777px 16.907px 68px 0px rgba(0, 0, 0, 0.2);
       .padding(32,20,20,0);
       @media @tablet-portrait,@mobile{
         margin:0;
         min-height: auto;
         .padding(34,15,20,16);
       }
       h3{
         margin-bottom: 2rem;
         padding-left: 1rem;
        .font-size(18);
        .MB;
        .line-height(32);
        .ttu;
        margin-bottom: 1rem;
        color: @primary;
        text-align: left;
       }
       .related-services-list{
         list-style: none;
         li{
           display: flex;
           align-items: flex-start;
           justify-content: flex-start;
           margin-bottom: 1.9rem;
           .icon{
              box-shadow: -2.034px 12.84px 27px 0px rgba(0, 0, 0, 0.3);
               border-radius: 0 0 0 1.25rem;
               display: block;
               .width(115);
               .height(70);
               margin-right: 1.25rem;
               margin-left: -1.125rem;
               position: relative;
               flex-shrink: 0;
               overflow: hidden;
               background-size: cover;
               background-position: center;
               background-repeat: no-repeat;
               @media @tablet-portrait,@mobile{
                 margin-left:0;
               }
               img{
                 width: 100%;
                 height: auto;
               }
           }
           .text{
             .font-size(15);
             .line-height(20);
             .MR;
             display: flex;
             flex-direction: column;
              a{
              color: @text;
              &:hover{
                color: @primary;
              }
             }
             .date{
              .font-size(12);
              .line-height(16);
              .MR;
              margin-top: 0.5rem;
              color: @black;
             }
             .place{
              .font-size(12);
              .line-height(16);
              .MR;
              margin-top: 0.5rem;
              color: @black;
              
             }
           }
         }
       }
       .add-more{
         margin: 0 auto;
         text-align:center;
         display: inline-block;
          height: 1rem;
          padding: 0rem 2.5rem 0rem 0rem;
          background: url(../img/arrow.png) no-repeat 90% 50%;
          background-size: 1rem auto;
          -webkit-transition: all 0.3s ease;
          -moz-transition: all 0.3s ease;
          -ms-transition: all 0.3s ease;
          -o-transition: all 0.3s ease;
          transition: all 0.3s ease;
          .font-size(14);
          .line-height(20);
          .MR;
          color: @primary;
          padding-left: 1.25rem;
          &:hover {
            text-decoration: none;
            background-position: 100% 50%;
        }
       }
     }
   }
   .slider-holder {
    .width(660);
    .padding(50,0,10,0);
    margin: 0 auto;
    @media @mobile{
      width: 100%;
    }
    .slider-events-inner{
      position: relative;
      z-index: 5;
      margin: 0 0 2rem;
      .padding(0,0,0,0);
      @media @mobile {
        margin: 0 0 0.5rem;
      }
      .slick-prev,
    .slick-next {
      position: absolute;
      .pos-centerY;
      .width(36);
      .height(36);
      border-radius: 50%;
      background-color: @secondary;
      color: @white;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      background-size: 100% auto;
      z-index: 200;
      @media @mobile{
       display: none!important;
      }
      &:hover{
        background-color: @primary;
      }
    }
    .slick-prev{
      .left(-50);
      @media @tablet{
        .left(-40);
      }
      @media @mobile{
        display: none;
      }
    }
    .slick-next{
      .right(-50);
      @media @tablet{
        .right(-40);
      }
      @media @mobile{
        display: none;
      }
    }
      .slick-slide {
        &:focus {
          outline: none;
        }
        .image {
         height: 23rem;
          background-repeat: no-repeat;
          background-position: 50% 50%;
          background-size: contain;
          @media @tablet {
            height: 23.875rem;
          }
          @media @tablet-portrait {
            height: 23.875rem;
          }
          @media @mobile {
            height: 20rem;
          }
        }
        .video {
          position:relative;
          z-index: 4;
          padding-bottom:56.25%;/*пропорции видео 16:9 */
          //padding-top:25px;
          height:0;
  
          video {
            display: block;
            width: 100%;
            height:auto;
          }
        }
      }
    }
    .slider-nav {
      z-index: 5;
      position: relative;
      .slick-list{
      .padding(0,0,0,0)!important;
      }
      .slick-slide {
       // height: 100%;
     
        &:focus {
          outline: none;
        }
        .img-wrap{
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100%;
        }
        .image {
          max-width:16.875rem;
          width: 100%;
          .margin(0,7,40,7);
          padding-bottom: 33%;
          min-height: 4.375rem;
          max-height: 5.5rem;
          background-repeat: no-repeat;
          background-position: 50% 50%;
          background-size: cover;
          position: relative;
          cursor: pointer;
          background-color: rgb(86, 45, 142);
          @media @mobile{
            .height(90);
          }
          // &:before {
          //   content: '';
          //   .full;
          //   background: rgba(0,0,0,0.4);
          // }
          &:hover {
            // &:before {
            //   background: rgba(0,0,0,0);
            // }
          box-shadow: -0.127rem 0.75rem 1.5rem 0px rgba(0, 0, 0, 0.3);
           
          }
        }
        video {
          display: block;
          width: 100%;
          height:auto;
        }
      }
      .slick-current {
        .image {
           border:0.25rem solid rgb(246, 213, 99);
           box-shadow: -0.127rem 0.805rem 1.5rem 0px rgba(0, 0, 0, 0.3);
          // &:before {
          //   background: rgba(0,0,0,0);
          // }
        }
      }

    }
    
  }
 }