.visually-hidden {
	clip: rect(1px 1px 1px 1px); /* IE 6/7 */
	clip: rect(1px, 1px, 1px, 1px);
	height: 1px;
	overflow: hidden;
	position: absolute;
	white-space: nowrap;
	width: 1px;
	margin: -1px;
}
.visuallyhidden {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
  }
.visible-when-focused:focus {
	clip: auto;
	height: auto;
	overflow: visible;
	position: static;
	white-space: normal;
	width: auto;
	margin: auto;
}
.bypass-block-link:focus {
	position: absolute;
	text-decoration: none;
	background: #ffffcc;
	padding: 0.2em;
	z-index: 10;
.bypass-block-target:focus {
	outline: none;
}

.bypass-block-target:focus:target,
a[href]:not([tabindex="-1"]):focus,
area:not([tabindex="-1"]):focus,
[role="button"]:not([tabindex="-1"]):focus,
[role="link"]:not([tabindex="-1"]):focus,
iframe:not([tabindex="-1"]):focus,
[contentEditable=true]:not([tabindex="-1"]):focus,
:enabled:not([tabindex="-1"]):focus,
[tabindex]:not([tabindex="-1"]):focus {
	outline: solid 2px @primary;
}
a[href]:not([tabindex="-1"]):hover:focus,
area:not([tabindex="-1"]):hover:focus,
[role="button"]:not([tabindex="-1"]):hover:focus,
[role="link"]:not([tabindex="-1"]):hover:focus,
iframe:not([tabindex="-1"]):hover:focus,
[contentEditable=true]:not([tabindex="-1"]):hover:focus,
:enabled:not([tabindex="-1"]):hover:focus,
[tabindex]:not([tabindex="-1"]):hover:focus {
	outline: solid 2px @primary;
}

a[href]:not([tabindex="-1"]):focus:active,
area:not([tabindex="-1"]):focus:active,
[role="button"]:not([tabindex="-1"]):focus:active,
[role="link"]:not([tabindex="-1"]):focus:active,
iframe:not([tabindex="-1"]):focus:active,
[contentEditable=true]:not([tabindex="-1"]):focus:active,
:enabled:not([tabindex="-1"]):focus:active,
[tabindex]:not([tabindex="-1"]):focus:active {
	outline: none;
}

:focus {
	outline: solid 2px @primary;
}
.placeholder-removed {
	opacity: 0.999;
}

[placeholder] {	
	background-color: #fff;
	border: solid 1px #777;
}

::-webkit-input-placeholder {
	 color: #777;
	 opacity: 1;
}

:-moz-placeholder { /* Firefox 18- */
	 color: #777;
	 opacity: 1;
}

::-moz-placeholder {  /* Firefox 19+ */
	 color: #777;
	 opacity: 1;
}

:-ms-input-placeholder {
	 color: #777;
	 opacity: 1;
}
}