.row:before, .row:after {display: none !important;}
* {
	margin:0;
	padding: 0;
}
html,
body {
	color: @black;
	min-height: 100vh;
	background: @white;
	overflow-x: hidden;
	.MR;
	@media (min-width:1441px) {
		font-size: 16px;
	}
	@media (max-width:1440px) {
		font-size: 14px;
	}
	@media (max-width:1280px) {
		font-size: 12px;
	}
	@media (max-width:1024px) {
		font-size: 13px;
	}
	@media @mobile{
		overflow-x: hidden;
	}
	&.userway-font30,
	.userway-font32{
		overflow-x: scroll;
		// news-card
	.news-section{
		.item{
			.main-text{
			p{
			max-height:unset;
			overflow-y: auto;
			}
			}
		}
		
	}
	}
	
}
img {
	vertical-align: top;
}
a {
	color: @primary;
	text-decoration: none;
	&:hover {
		color: @primary;
		text-decoration: underline;
	}
}
.overlay {
	padding-top: 0;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	overflow: hidden;
	z-index: 10;
	display:none;
	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.5);
		z-index: 100;
	}
	&.show {
		display: block;
	}
}
.no-scroll {
	@media @mobile {
		position: fixed;
		width:100%;
		height: 100%;
		overflow: hidden;
	}
}
.container {
	max-width: 80.25rem;
	margin: 0 auto;
	padding: 0;
	position: relative;
	@media screen and (min-width: 1970px){
		max-width: 91rem;
	}
	@media @tablet,@mobile{
		max-width: 90%;
	}
}
.container-big{
	max-width: 88.25rem;
	margin: 0 auto;
	padding: 0;
	position: relative;
	@media screen and (min-width: 1970px){
		max-width: 91rem;
	}
	@media @tablet,@mobile{
		max-width: 90%;
	}
}
.container-sm{
	max-width: 61.875rem;
	margin: 0 auto;
	padding: 0;
	position: relative;
	@media screen and (min-width: 1970px){
		max-width: 91rem;
	}
	@media @tablet,@mobile{
		max-width: 90%;
	}
}

.container-1{
	max-width: 92.5rem;
	margin: 0 auto;
	padding: 0;
	position: relative;
	@media screen and (min-width: 1970px){
		max-width: 91rem;
	}
	@media @tablet,@mobile{
		max-width: 90%;
	}
}
h1{
	color: @primary;
	.font-size(60);
	.MB;
	.ttu;
  }
h2{
	color: @white;
	.font-size(50);
	.MB;
	.ttu;
  }
h3{
	color: @primary;
	.font-size(40);
	.line-height(42);
	.MB;
	.ttu;
	text-align: center;
	@media @mobile{
		font-size: 2rem;
	}
}
 h4{
	color: @primary;
	.font-size(24);
	.line-height(40);
	.MB;
	letter-spacing: 0.02em;
	margin-bottom: 1.5rem;
  }
p{
	.MR;
	.font-size(15);
	.line-height(32);
	margin-bottom: 1.5rem;
	color: @text;
}
.btn {
	//.line-height(48);
	.ttu;
	background: @secondary;
	color:@primary;
	.font-size(16);
	.MB;
	border: 1px solid @secondary;
	cursor: pointer;
	border-radius: 1.5625rem;
	.transition(all 0.1s ease);
	outline: 0;
	white-space: nowrap;
	min-width: 10.625rem;
	padding: 0;
	.padding(12,0,12,0);
	flex-shrink: 0;
	&:hover {
		color: @primary;
		background: fade(@secondary, 90%);
		text-decoration: none;
	}
	&:focus {
		box-shadow:none;
		color:@white;
	
	}
	span{
		.padding(0,35,0,35);
		
	}
	&.btn-shadow{
		box-shadow: -1.046px 14.963px 20px 0px rgba(0, 0, 0, 0.32);
	  }
	  
	&.btn-white {
		background: @white;
		color:@primary;
		border-color:@white;
		&:hover {
			background: fade(@white, 90%);
		}
		&:focus,
		&:active {
			background: fade(@white, 90%);
		}
	}
	&.btn-red {
		background: @red;
		color:@white;
		border-color:@red;
		&:hover {
			background: fade(@red, 90%);
		}
		&:focus,
		&:active {
			background: fade(@red, 90%);
		}
	}
	&.btn-outline-white{
		border-color:@white;
		&:hover {
			background: fade(@secondary, 90%);
		}
		&:focus,
		&:active{
			background: fade(@secondary, 90%);
		}
	}
	&.btn-sec-outline{
		border-color:@secondary;
		background-color: @white;
		&:hover {
			background: fade(@secondary, 10%);
		}
		&:focus,
		&:active{
			color: @primary;
			background: fade(@secondary, 10%);
		}
	}
	&.btn-primary-outline{
		border-color:@primary;
		background-color: @white;
		&:hover {
			background: fade(@primary, 10%);
		}
		&:focus,
		&:active{
			color: @primary;
			background: fade(@secondary, 10%);
		}
	}
	&.btn-full {
		width:100%;
	}
}


/* loader start */
.loader {
	position: fixed;
	left:0;
	top:0;
	width:100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 9999;
	background: rgba(35,75,141,0.9);
}
.loader .square-spin > div {
//	-webkit-animation-fill-mode: both;
//	animation-fill-mode: both;
	.width(190);
	.height(190);
	background: url(../img/car-loader.png) no-repeat 50% 50%;
	background-size: contain;
//	-webkit-animation: square-spin 3s 0s cubic-bezier(0.09, 0.57, 0.49, 0.9) infinite;
	animation: bobble  1s ease-in-out infinite;
 }

 @keyframes bobble {
    0% {
        transform: translateX(12rem);
    }
    50% {
        transform: translateX(-12rem);
    }
    100% {
		transform: translateX(12rem);
    }
}

@-webkit-keyframes square-spin {
	25% {
		-webkit-transform: perspective(100px) rotateX(180deg) rotateY(0);
		transform: perspective(100px) rotateX(180deg) rotateY(0); }
	50% {
		-webkit-transform: perspective(100px) rotateX(180deg) rotateY(180deg);
		transform: perspective(100px) rotateX(180deg) rotateY(180deg); }
	75% {
		-webkit-transform: perspective(100px) rotateX(0) rotateY(180deg);
		transform: perspective(100px) rotateX(0) rotateY(180deg); }
	100% {
		-webkit-transform: perspective(100px) rotateX(0) rotateY(0);
		transform: perspective(100px) rotateX(0) rotateY(0); } }

@keyframes square-spin {
	25% {
		-webkit-transform: perspective(100px) rotateX(180deg) rotateY(0);
		transform: perspective(100px) rotateX(180deg) rotateY(0); }
	50% {
		-webkit-transform: perspective(100px) rotateX(180deg) rotateY(180deg);
		transform: perspective(100px) rotateX(180deg) rotateY(180deg); }
	75% {
		-webkit-transform: perspective(100px) rotateX(0) rotateY(180deg);
		transform: perspective(100px) rotateX(0) rotateY(180deg); }
	100% {
		-webkit-transform: perspective(100px) rotateX(0) rotateY(0);
		transform: perspective(100px) rotateX(0) rotateY(0); } }


/* loader end */



// //form 
.form-group {
	margin-bottom: 2rem;
	min-height: 3.125rem;
	position: relative;
	.form-control,
	.custom-select {
		height:3.125rem;
		background: @white;
		border:1px solid #d2d2d2;
		border-radius: 0.25rem;
		color:@text;
		.font-size(16);
		transition:  all .3s linear; 
		.padding(0,15,0,15);
		-webkit-appearance:  none; 
		//position: relative;
		&:focus{
			border:1px dashed @primary;
			box-shadow: none;
		}
		&:active{
			border:1px dashed @primary;
			box-shadow: none;
		}
		&::placeholder{
			.font-size(15);
			color: #a1a1a1;
			.MR;
			transition: 0.3s ease linear;
		}

	}
	.custom-select {
		background: #ffffff url(../img/svg/chevron-down-solid.svg) no-repeat right 0.5rem center;
		background-size: auto 0.5rem;
	}
	textarea.form-control{
		padding-top: 1rem;
		resize: none;
		.height(200);
		position: relative;	  
		padding-bottom: 1.5rem;
	}
	.form-check {
		padding: 0;
		.inline-b;
		margin-right: 1rem;
		position: relative;
		@media @tablet, @mobile {
			display: block;

		}
		.form-check-input-checkbox {
			width:1.25rem;
			height: 1.25rem;
			margin: 0 -1.25rem 0 0;
			position: absolute;
			left: 0;
			top:0;
			z-index: 3;
			opacity: 0;
			& + .form-check-label {
				.inline-b;
				.padding(0,0,0,34);
				position: relative;
				.font-size(15);
				.line-height(20);
				min-height: 1rem;
				color:#000;
				&:before {
					content: '';
					position: absolute;
					left:0;
					top:0;
					width:1.25rem;
					height:1.25rem;
					border: 1px solid #d2d2d2;
					background: #fff;
					border-radius: 0.25rem;
				}
				&:after {
					border-radius: 0.25rem;
					content: '\f00c';
					position: absolute;
					top: 0;
					left: 0;
					width: 1.25rem;
					height: 1.25rem;
					line-height: 1.25rem;
					text-align: center;
					font-family: 'Font Awesome 5 Free';
					font-weight: 900;
					opacity: 0;
					color: #ffffff;
				}

			}
			&:checked {
				& + .form-check-label {
					&:before {
						background: @primary;
						border: 1px solid @primary;
					}
					&::after{
						opacity: 1;
					}
				}
			}
		}

	}
}

	





//animation
@keyframes pulse-round {
    0% {
        transform: scale(1);
    }

    25% {
        transform: scale(1.1);
    }

    50% {
        transform: scale(1);
    }

    90% {
        transform: scale(1.075);
    }

    100% {
        transform: scale(1);
    }
}
.pulse-round{
	animation: pulse-round 4.5s infinite ease-in-out;
}


@keyframes circle-rotate {
	0% {
	  transform: rotate(0deg);
	}
	50% {
	  transform: rotate(180deg);
	}
	100% {
	  transform: rotate(360deg);
	}
  }


 @keyframes pulse-icon {
	0%,
	100% {
	 transform: rotate(-10deg);
  //    transform: scale(1);
	}
	50% {
	  transform: scale(1.1);
	}
  }
   @keyframes pulse-icon-3{
	0%,
	100% {
	 transform: rotate(-10deg);
  	
	}
	50% {
	  transform: scale(1.05);
	  transform: scale(0.9);
	}
  }
  @keyframes pulse-icon-1 {
	0%,
	100% {
	 transform: rotate(5deg);
  //    transform: scale(1);
	}
	50% {
	  transform: scale(1.2);
	}
  }
  @keyframes pulse-icon-2 {
	0%,
	100% {
	 transform: rotate(-5deg);
  //    transform: scale(1);
	}
	50% {
	  transform: scale(1.2);
	}
  }
  @keyframes pulse-icon-enlarge {
	0%,
	100% {
  		transform: scale(0.9);
	}
	50% {
	  transform: scale(1.2);
	}
	75% {
		transform: scale(1.1);
	  }
  }

  @keyframes pulse-icon-enlarge-1 {
	0%,
	100% {
  		transform: scale(1);
	}
	50% {
	  transform: scale(1.08);
	}
  }

  
  a,p,h1,h2,h3,h4,h5,img,div,footer,h6,span,section{
	&:focus{
		outline: 1px dashed @primary;
	}
  }


body.modal-open {
	overflow: hidden;
	position: fixed;
	width:100%;
	}