.home-page{
  .banner {
  position: relative;
  overflow: hidden;
  max-height: 100vh;
  @media @tablet,@mobile{
    height: auto;
  }
  @media @mobile{
    margin-top: 3.875rem;
  }
    @media @mobile-xs{
    margin-top: 3.5rem;
  }
  // &:before {
  //   position: absolute;
  //   display: block;
  //   top: 0;
  //   left: 0;
  //   right: 0;
  //   bottom: 0;
  //   content: "";
  //   background: rgba(0,0,0,.2);
  //   z-index: 10;
  //   @media @mobile{
  //     bottom: 1px;
  //   }
  // }
  .scroll-down {
    z-index: 26;
    position: absolute;
    left: 16%;
    bottom: 0;
    color: #ffffff;
    .MR;
    .font-size(14);
    width: 1.250rem;
    height: 9.063rem;
    text-align: center;
    @media @desktop-lg,@tablet{
      left:10%;
    }
    &:before {
      content: '';
      position: absolute;
      
      bottom: 0;
      width: 0.125rem;
      .height(60);
      background: #ffffff;
      .pos-centerX;
  }
  span {
    position: absolute;
    letter-spacing: 0.3em;
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    left: -1.3rem;
    top: 1.938rem;
  }
}
.banner-right-deco{
  position: absolute;
  right: 0;
  bottom: 0;
  height: 100%;
}
.banner-right-deco-2{
  position: absolute;
  right: 0;
  bottom: 0;
  height: 100%;
}
  .banner-inner{
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      color: #fff;
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-align: center;
      z-index: 10;
      @media @mobile{
        align-items: flex-start;
      }
      .text {
        // width:52%;
        color:@white;
        @media @tablet,@mobile{
          width: 55%;
        }
        @media @mobile{
          margin-top: 4rem;
         width: auto;
        }
        .text-inner {
          text-align: left;
          max-width: 32.62rem;
          @media @tablet-portrait, @mobile {
            margin-bottom: 2rem;
          }
          @media @mobile-xs {
            margin: 1rem 0;
          }
        }
        .btn{
          @media @mobile{
            padding: 0 1.875rem;
          .line-height(30);
          min-width: auto;   
          span{
            padding: 0;
          }   
          }
        }
        .line-1 {
          .MB;
          .font-size(50);
          .line-height(60);
          .margin(0,0,40,0);
          color: @white;
          @media @tablet {
            .font-size(44);
            .line-height(55);
            .margin(0,0,10,0);
          }
          @media @mobile {
            .font-size(30);
            .line-height(35);
            .margin(0,0,10,0);
          }
          @media @mobile-xs {
            .font-size(20);
            .line-height(34);
            .margin(0,0,10,0);
          }
        }
      }
      
  }

  .bg-banner {
    width:100%;
    height: auto;
    @media @mobile{
      height: 30rem;
    }
  }
  // .video-holder {
  //   video {
  //     left: 0;
  //     top: 0;
  //     bottom: 0;
  //     width: 100%;
  //     height: auto;
  //     // @media (min-aspect-ratio: 16/9) {           
  //     //   height: 400%;
  //     //   top: -150%;         
  //     // }  
  //     // @media (max-aspect-ratio: 16/9) {
  //     //   width: 400%;
  //     //   left: -150%;         
  //     // }
  //     // @media @mobile {
  //     // margin-left: 0;
  //     // top: -20px;
  //     // height: ~"calc(100% + 20px)";          
  //     // }
  //   }
  // }

  .wrap-slider{
    max-width: 50%;
    margin-left: auto;
  }
  .banner-slider {
    z-index: 10;
    .full;
    width: 55%;
    margin-left: auto;
    @media @mobile{
      width: 82%;
      margin-left: auto;
      top: unset;
      bottom: 2rem;
    }
    &:focus{
      outline: none;
    }
    .slick-list,
    .slick-track,
    .item {
      display: flex;
      align-items: center;
      height: 100%;
      .padding(60,60,60,60);
      @media @tablet{
        .padding(60,20,60,30);
      }
      @media @mobile{
        .padding(10,20,10,20);
      }
      .item-inner {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-position: 100% 100%;
        background-size: 100% auto;
        background-repeat: no-repeat;
        .width(800);
        .height(500);
        border: 2px solid @white;
        border-radius: 0px 0px 0px 50px;
        box-shadow: -9.193px 36.871px 76px 0px rgba(0, 0, 0, 0.42);
        @media only screen and (min-width:1440px) and (max-width: 1679px){
          .width(620);
          .height(387);
        }
        @media @desktop-lg{
          .width(620);
          .height(387);
        }
        @media @tablet{
          .width(590);
          .height(367);
        }
        @media @tablet-portrait{
          .width(400);
          .height(250);
        }
        @media @mobile{
          .width(300);
          .height(187);
          box-shadow: none;
        }
      }
    }
    // .slick-dots {
    //   position: absolute;
    //   .pos-centerX;
    //   .bottom(75);
    //   display: flex;
    //   padding-right: 15px;
    //   padding-left: 15px;
    //   margin: 0;
    //   // @media only screen and (min-width:1440px) and (max-width: 1679px){
    //   //   left: 15.1rem;
    //   // }
    //   // @media @desktop-lg{
    //   //   left: 15.1rem;
    //   //   .bottom(40);
    //   // }
    //   @media @tablet{
        
    //     .pos-centerX;
    //     width: auto;
    //   }
    //   @media @mobile{
    //     bottom: -2rem;
    //     .pos-centerX;
    //     width: auto;
    //   }
    //   li {
    //     list-style: none;
    //     .margin(0,12,0,0);
    //     @media only screen and (max-width: 400px){
    //       .margin(0,8,0,0);
    //     }
    //    button {
    //       border:1px solid @white;
    //       .width(12);
    //       .height(12);
    //       padding: 0;
    //       overflow: hidden;
    //       text-indent: -999px;
    //       border-radius: 50%;
    //       flex-shrink: 0;
    //       background: transparent;
    //       @media @mobile{
    //         .width(8);
    //         .height(8);
    //       }
    //     }
    //     &.slick-active {
    //       button {
    //         background: @white;            
    //       }
    //     }
    //   }
    // }

    .slick-dots-container {
      overflow: hidden;
      display: block;
      position: absolute;
      left: 50%;
      bottom: 4.6875rem;
      width: 6rem;
      padding: 0;
      margin: 0.625rem auto;
      height: 0.875rem;
      @media @tablet-portrait{
        left: 40%;
      }
  }

  .slick-dots-container > ul {
      padding: 0;
      display: flex;
      transition: all 0.25s;
      position: relative;
      margin: 0;
      list-style: none;
      transform: translateX(0);
      align-items: center;
      bottom: unset;
      height: 100%;
      // @media @tablet{
      //     .pos-centerX;
      //     width: auto;
      // }
      @media @mobile{
          display: none;
      }
  }

  .slick-dots-container > ul li {
      width: 0.75rem;
      height: 0.75rem;
      margin: 0 0.25rem;
      border: none;
      border-radius: 50%;
      @media only screen and (max-width: 400px){
          .margin(0,8,0,0);
      }
  }

  .slick-dots-container > ul li button {
      border: 1px solid #fff;
      width: 0.75rem;
      height: 0.75rem;
      padding: 0;
      overflow: hidden;
      text-indent: -999px;
      border-radius: 50%;
      flex-shrink: 0;
      background: transparent;
      @media @mobile{
          .width(8);
          .height(8);
      }
  }

  /* .slick-dots-container > ul li.p-small-1,
   .slick-dots-container > ul li.n-small-1 {
     transform: scale(0.8);
   }*/

  .slick-dots-container > ul li.slick-active {
      transform-origin: center;
      background: @white;
  }

  .slick-dots li button:before {
      display: none;
  }

    
      .image-wrap{
        .width(800);
        .height(500);
        margin-top: 1.7rem;
        margin-left: -1.6rem;
        flex-shrink: 0;
        position: relative;
        overflow: hidden;
        background-size: 100% auto;
        background-position: center;
        background-repeat: no-repeat;
        border-radius: 0px 0px 0px 50px;
        box-shadow: -9.193px 36.871px 76px 0px rgba(0, 0, 0, 0.42);
        
        @media only screen and (min-width:1440px) and (max-width: 1679px){
          .width(620);
          .height(387);
        }
        @media @desktop-lg{
          .width(620);
          .height(387);
        }
        @media @tablet{
          .width(590);
          .height(367);
        }
        @media @tablet-portrait{
          .width(400);
          .height(250);
        }
        @media @mobile{
          .width(300);
          .height(187);
          box-shadow: none;
        }
        .image{ 
          width: 100%;
          height: 100%;
          flex-shrink: 0;
          position: relative;
          background-size: cover;
          background-position: 50% 0%;
          background-repeat: no-repeat;
        }
      }
    }
  }/* banner end */

  .police-foundation{
    .padding(100,0,230,0);
    background-size: 100% auto;
    background-position: 50% 100%;
    background-repeat: no-repeat;
    @media @tablet-portrait{
      .padding(70,0,120,0);
    }
    @media @mobile{
      .padding(70,0,120,0);
    }
    h3{
      margin-bottom: 4.5rem;
      @media @mobile{
        margin-bottom: 2rem;
      }
    }
    .image-side{
      position: relative;
      .width(450);
      .height(450);
      border-radius: 50%;
      border: 2px solid @primary;
      @media @tablet,@mobile{
        margin: 0 auto;
      }
      @media @mobile-xs{
        .width(340);
        .height(340);
      }
      .car{
        position: absolute;
        .width(545);
        top:0.5rem;
        height: auto;
        .left(-95);
        @media @mobile-xs{
          .width(370);
          top:1rem;
          .left(-45);
        }
      }
      .logo{
        position: absolute;
        .width(130);
        .height(130);
        border-radius: 50%;
        border: 0.75rem solid @white;
        background-size: 6.625rem auto;
        background-position: center;
        background-repeat: no-repeat;
        right: 0;
        top:0;
      }
    }
    .text{
      padding-top: 3.75rem;
      padding-right: 4rem;
      @media @mobile{
        padding-right: 0;
      }
    p{
      margin-bottom: 2.5rem;
    }
    }
  }
  .events-section{
    .padding(97,0,83,0);
    @media @mobile{
      .padding(57,0,0,0);
    }
    h3{
      @media @mobile{
        margin-bottom: 4rem;
      }
    }
    .events-slider{
      .item{
        &:focus{
          outline: none;
        }
        .item-inner{
        border-radius: 0 0 0 1.25rem;
         background-color: @white;
         .margin(40,10,60,70);
         min-height: 21.875rem;
         display: flex;
         flex-direction: column;
         position: relative;
         box-shadow: 0.125rem 1rem 4.25rem 0px rgba(0, 0, 0, 0.2);
         box-shadow: -14px 9px 36px -12px rgba(0,0,0,0.2);
         transition: all 0.3s ease-in-out;
         min-width: 17.5rem;
        .image-hold {
          .margin(-11,36,22,-36);
          position: relative;
          .height(172);
          border-radius: 0 0 0 1.25rem;
          box-shadow: -2.034px 12.84px 27px 0px rgba(0, 0, 0, 0.3);
          @media @mobile{
            .height(200);
          }
          .date{
            position: absolute;
            top: 0;
            left: 0;
            .MR;
            .font-size(13);
            .line-height(26);
            .padding(4,15,4,15);
            color: @primary;
            background-color: @secondary;
            z-index: 2;
          }
          .image {
          height: 100%;
          position: relative;
          overflow: hidden;
          border-radius: 0 0 0 1.25rem;
            .img {
              border-radius: 0 0 0 1.25rem;
              height: 100%;
              display: block;
              background-size: cover;
              background-position: 50% 50%;
              background-repeat: no-repeat;
              transition: all 0.3s ease;
            }
          }
        }
        &:hover{
          background-color: @primary;
          color: @white;
          .main-text{
              h3{
                a{
                  color: @white;
                }
              }
              p{
                color: @white;
              }
              .location{
                color: @white;
                &::before{
                  color: @white;
                }
              }
              .time{
                  color: @white;
                  &::before{
                    color: @white;
                  }
              }
          }
          .image-hold{
            .img{
              transform: scale(1.1);
              @media only screen and (min-width: 767.99px) and (max-width: 1199.98px), only screen and (max-width: 767.98px) {
                transform: scale(1.05);
              }
            }
          }
        }
        .main-text{
          .padding(0,25,30,25);
          text-align: left;
          .location{
            .font-size(14);
            color: @black;
            .MR;
            .line-height(24);
            padding-left:1.25rem;
            position: relative;
            &::before{
              content: "\f3c5";
              position: absolute;
              top:0.2rem;
              left: 0;
              color: @primary;
              width: .8rem;
              height: auto;
              font-family: Font Awesome\ 5 Free;
              font-weight: 900;
            }
          }
          .time{
            .font-size(14);
            color: @black;
            .MR;
            .line-height(24);
            padding-left:1.25rem;
            position: relative;
            &::before{
              content: "\f017";
              position: absolute;
              top:0.2rem;
              left: 0;
              color: @primary;
              width: .7rem;
              height: auto;
              font-family: Font Awesome\ 5 Free;
              font-weight: 900;
            }
          }
            h3{
              text-align: left;
              .font-size(16);
              .MB;
              color: @black;
              .line-height(26);
              margin-bottom: 0.5rem;
              text-transform: none;
              a{
              color: @black;
              }
            }
            p{
              .font-size(14);
              color: @black;
              .MR;
              .line-height(24);
              margin-bottom: 1rem;
            }
          
        }
      }
    }
      .slick-prev,
      .slick-next {
        position: absolute;
        bottom: -2.25rem;
        .width(36);
        .height(36);
        border-radius: 50%;
        background-color: @secondary;
        color: @white;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        background-size: 100% auto;
        z-index: 200;
        @media @mobile{
          top:-3rem;
        }
        &:hover{
          background-color: @primary;
        }
      }
      .slick-prev{
        left: 43%;
        @media @tablet{
          left: 40%;
        }
        @media @mobile{
          left: 1rem;
        }
      }
      .slick-next{
        right: 43%;
        @media @tablet{
          right: 40%;
        }
        @media @mobile{
            right:1rem;
        }
      }
    }
    .view-all{
      @media @mobile{
        position:absolute;
        .pos-centerX;
        top: -3rem;
      }
      .view-all-link{
        display: inline-block;
        .ttu;
        .MB;
        .font-size(14);
        .line-height(30);
        color:@primary;
      }
    }
  }
  
  .mission-section{
    .padding(289,0,115,0);
    min-height:63rem;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: @primary;
    @media screen and (min-width: 2000px){
      min-height:81rem;

    }
    @media @tablet-portrait{
      .padding(50,0,0,0);
      background-image: none!important;
      background-color: transparent;
    }
    @media @mobile{
      .padding(50,0,0,0);
    min-height:auto;
    background-image: none!important;
    background-color: transparent;
    }
    .bg-image{
      width: 100%;
      display: none;
      @media @tablet-portrait,@mobile{
        display: block;
      }
    }
    .image-wrap{
      position: relative;
      .width(1467);
      .padding(0,0,164,0);
      @media @desktop-lg,@tablet,@mobile{
        width: 100%;
      }
      @media @tablet-portrait{
        margin: 0 auto;
        .width(450);
        .height(450);
        border-radius: 50%;
        border: 1px dashed @primary;
        margin-bottom:4rem;
      }
      @media @mobile{
        margin: 0 auto;
        .width(380);
        .height(380);
        border-radius: 50%;
        border: 1px dashed @primary;
        margin-bottom:4rem;
      }
      @media only screen and (max-width: 385.98px){
        margin: 0 auto;
        .width(330);
        .height(330);
        border-radius: 50%;
        border: 1px dashed @primary;
        margin-bottom:4rem;
      }
      
      .line{
        width: 100%;
        height: auto;
        @media @mobile{
          display: none;
        }
      }
      .logo{
        position: absolute;
        .width(281);
        bottom:0;
        .left(-113);
        animation: pulse-icon 5s ease-in-out infinite;
        @media @tablet{
          .width(160);
          .left(-40);
          bottom: 5rem;
        }
        @media @tablet-portrait{
          .width(180);
          bottom:unset;
          top: 0;
          left: unset;
          .pos-center;
          margin: -5.625rem 0 0 -5.625rem;
        }
        @media @mobile{
          .width(140);
          bottom:unset;
          top: 0;
          left: unset;
          .pos-center;
          margin: -4.375rem 0 0 -4.375rem;
        }
      }
      .circle{
        position: absolute;
        .width(240);
        .height(240);
        display: flex;
        justify-content: center;
        align-items: center;
        top:0;
        @media @tablet{
          .width(180);
          .height(180);
        }
        @media @mobile {
         // position: relative;
          .width(180);
          .height(180);
        }
        .circle-wrap{
          animation: pulse-icon-enlarge-1 4s ease-in-out infinite;
          .width(240);
          .height(240);
          .pos-center;
          border: 1px solid @white;
          background-color: transparent;
          border-radius: 50%;
          margin: -7.5rem 0 0 -7.5rem;
          @media @tablet{
            .width(180);
            .height(180);
            margin: -5.625rem 0 0 -5.625rem;
          }
        @media @mobile {
          position: absolute;
          .width(140);
          .height(140);
          margin: -4.375rem 0 0 -4.375rem;
        }
        }
        .circle-inner{
          .width(200);
          .height(200);
          border-radius: 50%;
          background-color: @primary;
          color: @white;
          display: flex;
          justify-content: center;
          align-items: center;
          .font-size(18);
          .line-height(26);
          .MR;
          box-shadow: -2.034px 12.84px 27px 0px rgba(0, 0, 0, 0.3);
          .padding(0,30,0,30);
          text-align: center;
          @media @tablet{
            .width(150);
            .height(150);
            .font-size(16);
          .line-height(23);
          }
         @media @mobile{
          .width(115);
          .height(115);
          .font-size(12);
          .line-height(20);
         }
        }
        &.circle-1{
          .top(67);
       //   .right(975);
          right: 67%;
          @media @tablet-portrait{
            right: unset;
            .top(-27);
            left:-3%;
          }
          @media @mobile{
            right: unset;
            .top(-27);
            left:-3%;
          }
          @media only screen and (max-width: 385.98px){
            .top(-47);
          }
        }
        &.circle-2{
          .top(136);
      //    .right(669);
          right: 45.6%;
          @media @tablet-portrait{
            right: unset;
            top:unset;
            .bottom(27);
            left: -3rem;
          }
          @media @mobile{
            right: unset;
            top:unset;
            .bottom(27);
            left: -3rem;
          }
          @media only screen and (max-width: 385.98px){
            .bottom(0);
          }
        }
        &.circle-3{
          .top(117);
        //      .right(351);
        right: 24.3%;
        @media @tablet-portrait{
          top:unset;
          right: -3%;
          .bottom(-27);
        }
        @media @mobile{
          top:unset;
          right: -3%;
          .bottom(-27);
        }
        @media only screen and (max-width: 385.98px){
          .bottom(-47);
        }
        }
        &.circle-4{
          .top(-32);
          right: 5%;  
          @media @tablet-portrait{
            right: unset;
            .top(27);
            right: -3rem;
          }
          @media @mobile{
            right: unset;
            .top(27);
            right: -3rem;
          }
          @media only screen and (max-width: 385.98px){
            .top(0);
          }
        }
      }
    }
    .text{
      color: @white;
      .font-size(18);
      .MB;
      .line-height(28);
      text-align: center;
      @media @tablet-portrait{
        color: @text;
        .font-size(16);
        .MR;
      }
      @media @mobile{
        color: @text;
        .font-size(16);
        .MR;
        text-align: left;
      }
    }
  }
}

// donation section
.donation-section{
  background-position: 50% 100%;
  background-repeat: no-repeat;
  background-size: 100% auto;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 18.5rem;
  background-color: @primary;
  @media @mobile{
    flex-direction: column;
    background-color: unset;
    min-height: auto;
  }
  &:focus{
    outline: 1px dashed @white;
  }
  // .height(600);
  // @media @tablet{
  //   .height(350);
  // }
  img{
    width: 100%;
    height: auto;
    position: relative;
  }
  .text-wrap{
    margin-top: 3.5rem;
    position: absolute;
    text-align: center;
    z-index: 2;
    @media @mobile{
      position: relative;
      margin-top: 0;
    }
    h3{
      color:@white;
      text-transform: none;
      .font-size(60);
      .MB;
      margin-bottom: 2.5rem;
      @media @tablet{
        .font-size(40);
        margin-bottom: 2rem;
      }
      @media @mobile{
        .font-size(25);
        .line-height(40);
        color: @primary;
        margin-bottom: 1.5rem;
      }
    }
  }
}