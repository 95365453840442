.header {
	position: fixed;
	left:0;
	top:0;
	width:100%;
	z-index: 1000;
//	background: @white;
	.transition(all 0.3s ease);	
	box-shadow: -6.761px 1.812px 6px 0px rgba(0, 0, 0, 0.26);
	&.header--black{
		/*hamburger btn*/
		.btn-lines {
			background-color: @red;
		}
		.c-hamburger {
			span {
				background: @red;
			}
		}
		.btn-lines, .c-hamburger span:after, .c-hamburger span:before {

			background-color: @red;
		}
		.c-hamburger--htx.active span {
			background: none;
		}
		.top-line{
			background-color: @black;
			.top-line-inner{
				h3{

					color: rgba(255, 255, 255, 0.8);
					letter-spacing: 0.1em;
				}

			}

		}
		.header-inner{
			background-color: #242424;
		}
		.nav-holder {
			@media @tablet, @mobile {
				background: @black;
			}
			& > ul {

				& > li {

					&:focus{
						outline: 1px dashed @red;
					}


					& > a {

						&:hover{

							color:@red;
							@media @tablet,@mobile{
								color: @red;
							}
						}
						&:focus{
							outline: 1px dashed @red;
						}

					}
					&.active {
						a {
							color:@red;
							&:focus{
								outline: 1px dashed @red;
							}
						}
					}
					.dropdown {
						& > ul {
							background: #242424;
							@media @tablet,@mobile{
								background-color: transparent;
							}
							& > li {
								&:hover{
									background-color: @red;
									@media @tablet,@mobile{
										background-color: transparent;
									}
									& > a {
										color: @white;
										@media @tablet,@mobile{
											color: @red;
										}
									}
								}
								&.active{
									background-color: @red;
									cursor: pointer;
									@media @tablet,@mobile{
										background-color: transparent;
									}
									& > a {
										color: @red;
										@media @tablet,@mobile{
											color: @red;
										}
									}
								}
								& > a {
									.ttu;
									color: @white;
									.font-size(15);
									.line-height(20);
									@media @tablet, @mobile {
										display: block;
										padding: 1.5rem 2rem;
										text-align: center;
										color:@white;
									}
								}

							}
						}
					}

				}
			}
			.buttons{
				@media @tablet,@mobile {
					padding-top: 3rem;
					background: black;
				}
			}
		}

	}

	&.scrolling{
		.transition(all 500ms ease);
		.top-line{	
			.logo {
				.width(70);
				.transition(all 500ms ease-in-out);
					
			}
		}
	 }
	.top-line{
		background-color: @white;
		width: 100%;
		.padding(6,0,6,0);
		.top-line-inner{
			display: flex;
			justify-content: space-between;
			align-items: center;
			@media @tablet,@mobile{
				display: flex;
			//	justify-content: flex-start;
				align-items: center;
				.padding(0,40,0,0);
			//	max-width: 95%;
				
			}
			.left-side{
				display: flex;
				align-items: center;
			}
			.right-side {
				display: flex;
				align-items: center;
				justify-content: flex-end;
				.cart{
					margin-right: 1.5rem;
					.font-size(18);
					white-space: nowrap;
					@media @tablet{
						margin-left: 4rem;
					}
					@media @mobile{
						margin-left: 2rem;
					}
					@media @mobile-xs{
						margin-left: 0.25rem;
					}
					&:hover{
						text-decoration: none;
						i{
							.font-size(20);
						}
					}
					i{
						margin-right: 0.1rem;
					}
					em{
						margin: 0 0.25rem 0 0.25rem;
						font-style: normal;
					}
				}
			}
		}
		.logo {
			display: block;
			.width(106);
			flex-shrink: 0;
			.transition(all 0.3s ease);
			margin-right: 1.5rem;
			@media @tablet{
				.width(86);
			}
			@media @mobile{
				.width(56);
			}
			@media @mobile-xs{
				.width(46);
			}

			&.logo-2{
				margin-right: 0;
				margin-left: 2rem;
				@media @mobile-xs{
					margin-left: 0.25rem;
				}
			}
			img {
				width: 100%;
				height: auto;
			}
		}
		.btn{
			//.line-height(38);
			min-width: unset;
			border-radius: 1.125rem;
			.padding(7,0,7,0);

			@media @mobile{
				display: none;
			}
			span{
				padding: 0 1.5rem;
			}
		}
		h3{
			font-weight: 900;
			margin: 0;
			.font-size(36);
			.letter-spacing(100);
			text-align: left;
			@media @tablet{
				.font-size(20);
				.line-height(30);
			}
			@media @mobile{
				.font-size(15);
				.line-height(20);
			}
			@media @mobile-xs{
				.font-size(13);
				.line-height(20);
			}
		}
	}
	
	.header-inner {
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-direction: column;
		width:100%;
		background-color: @primary;
		// @media @tablet, @mobile {
		// 	justify-content: flex-end;
		// }
	}
	.nav-holder {
		width:100%;
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		z-index: 2;
		.padding(4,0,4,0);
		@media @tablet, @mobile {
			position: fixed;
			right:-100%;
			//left:0;
			box-shadow: none;
			top:0;
			height: 100%;
			margin: 0;
			width:30rem;
			.padding(180,0,0,0);
			background: @primary;
			display: flex;
			justify-content: flex-start;
			flex-direction: column;
			.transition(all 0.5s ease);
			&.show {
				right: 0;
			}
		}
		@media @mobile {
			width:100%;
		}
		.buttons {
			z-index: 3;
			margin-bottom: 0.5rem;
			width: 100%;
			text-align: center;
			.btn{
				@media @mobile{
					
					width:50%;
					margin: 0;
				}
			}
		}
		.nav-logo-wrap{
			display: none;
			position: absolute;
			left:1rem;
			//width:4rem;
			@media @tablet, @mobile {
				left: 0;
				width: 100%;
				top:1rem;
				display: flex;
				justify-content: center;
				align-items: center;
				padding: 0 4rem;
				.logo-link{
					margin: 0 1rem;
					width:8rem;
					.nav-logo {
						width: 100%;
					}
				}
			}
		}

		& > ul {
			display: flex;
			justify-content: space-around;
			align-items: center;
			list-style: none;
			margin: 0;
			@media @tablet, @mobile {
				height: 100%;
				width:100%;
				overflow: auto;
				flex-direction: column;
				flex-wrap: wrap;
				justify-content: center;
				align-items: center;
				display: block;
			}
			& > li {
				.margin(3,30,3,30);
				position: relative;
				@media @tablet, @mobile {
					margin: 0;
					width:100%;
					text-align: center;
					//display: flex;
					//justify-content: center;
					border-top:1px solid rgba(255,255,255,0.1);
					&:last-child {
						border-bottom:1px solid rgba(255,255,255,0.1);
					}
				}
				&:focus{
					outline: 1px dashed @white;
				}
				&:hover {
					.dropdown {
						display: block;
					}
				}
				
				& > a {
					display: block;
					.ttu;
					.font-size(15);
					.line-height(30);
					color:@white;
					.MR;
					@media @tablet, @mobile {
						display: block;
						padding: 1.5rem 2rem;
						text-align: center;
						color:@white;
						.font-size(18);
						font-weight: 700;
					}
					&:hover{
						text-decoration: none;
						color:@secondary;
						@media @tablet,@mobile{
							color: @white;
							text-decoration: underline;
						}
					}
					&:focus{
						outline: 1px dashed @white;
					}
					i {
						.margin(0,0,0,3);
					}
					img{
						.width(95);
						height: auto;
					}
				}
				&.active {
					a {
						text-decoration: none;
						color:@secondary;
						&:focus{
							outline: 1px dashed @white;
						}
					}
				}
				.dropdown {
					position: absolute;
					.width(200);
					left:0;
					.top(30);
					.padding(10,0,0,0);
					display: none;
					border-radius: 0;
					@media @tablet, @mobile {
						display: block;
						position: static;
						padding: 0;
						width:auto;
					}
					& > ul {
						box-shadow: 0.939px 5.926px 16px 0px rgba(0, 0, 0, 0.2);
						list-style: none;
						margin: 0;
						background: @primary;
						background-size: auto 0.375rem; 
						box-shadow:0 3px 3px rgba(0,0,0,0.1);
						@media @tablet, @mobile {
							background: none;
							border-radius: 0;
							padding: 0;
							border-top:1px solid rgba(255,255,255,0.1);
							box-shadow: none;
						}
						& > li {
							margin: 0;
							.padding(8,10,8,19);
							@media @tablet, @mobile {
								margin: 0;
								width:100%;
								border-bottom:1px solid rgba(255,255,255,0.1);
								padding: 0;
								&:last-child {
									border-bottom:0;
								}
							}
							&:hover{
								background-color: @secondary;
								cursor: pointer;
								@media @tablet,@mobile{
									background-color: @primary;
								}
								& > a {
									color: @primary;
									text-decoration: none;
									@media @tablet,@mobile{
										color: @white;
										font-weight: 700;
										text-decoration: underline;
									}
								}
							}
							&.active{
								background-color: @secondary;
								cursor: pointer;
								@media @tablet,@mobile{
									background-color: @primary;
								}
								& > a {
									color: @primary;
									@media @tablet,@mobile{
										color: @secondary;
									}
								}
							}
							& > a {
								.ttu;
								color: @white;
								.font-size(15);
								.line-height(20);
								@media @tablet, @mobile {
									display: block;
									padding: 1.5rem 2rem;
									text-align: center;
									color:@white;
								}
							}
							
						}
					}
				}

			}
		}
	}
	
}

/*hamburger btn*/
.btn-lines {
	position: absolute;
	display: block;
	left: 0;
	width: 100%;
	.height(2);
	background-color: @primary;
	content: "";
	-webkit-transition: all 0.3s ease;
	transition: all 0.3s ease;
}
.c-hamburger {
	display: none;
	//overflow: hidden;
	position: absolute;
	z-index: 10;
	right:-1.5rem;
	//top:1rem;
	margin: 0;
	padding: 0;
	.width(26);
	.height(20);
	flex-shrink: 0;
	font-size: 0;
	text-indent: -9999px;
	appearance: none;
	border: none;
	cursor: pointer;
	-webkit-transition: all 0.3s ease 0.3s;
	transition: all 0.3s ease  0.3s;
	@media @tablet{
		right:-2.5rem;
	}
	@media @mobile{
		right:-1.5rem;
	}
	@media @mobile-xs{
		right:0;
	}
	&:focus {
		outline: none;
	}
	@media @tablet, @mobile {
		display: block;
	}
	span {
		display: block;
		position: absolute;
		.top(9);
		left: 0;
		right: 0;
		.height(2);
		background: @primary;
		-webkit-transition: all 0.3s ease;
		transition: all 0.3s ease;
	}
	span::before {
		&:extend(.btn-lines);
		.top(-7);
	}
	span::after {
		&:extend(.btn-lines);
		.bottom(-7);
	}
}
.c-hamburger--htx {
	background-color: transparent;
	&.active {
		&:before {
			display: none;
		}
		span {
			background: none;
		}
		span::before {
			top: 0;
			transform:rotate(45deg);
			-webkit-transform:rotate(45deg);
			background: @white;
		}
		span::after {
			bottom: 0;
			transform:rotate(-45deg);
			-webkit-transform:rotate(-45deg);
			background: @white;
		}
	}
}
/*hamburger btn*/