@import "_variables.less";

@font-face {
  font-family: 'Font Awesome 5 Free';
  font-style: normal;
  font-weight: 900;
  font-display: @fa-font-display;
  src: url('@{fa-font-path}/fa-solid-900.eot');
  src: url('@{fa-font-path}/fa-solid-900.eot?#iefix') format('embedded-opentype'),
    url('@{fa-font-path}/fa-solid-900.woff2') format('woff2'),
    url('@{fa-font-path}/fa-solid-900.woff') format('woff'),
    url('@{fa-font-path}/fa-solid-900.ttf') format('truetype'),
    url('@{fa-font-path}/fa-solid-900.svg#fontawesome') format('svg');
}


.fa,
.fas {
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
}

@font-face {
  font-family: 'Brandon Grotesque Light';
  font-style: normal;
  font-weight: 300;
  src: local('Brandon Grotesque Light');
}


@font-face {
  font-family: 'Brandon Grotesque';
  src: url('@{fa-font-path}/subset-BrandonGrotesque-Bold.eot');
  src: url('@{fa-font-path}/subset-BrandonGrotesque-Bold.eot?#iefix') format('embedded-opentype'),
      url('@{fa-font-path}/subset-BrandonGrotesque-Bold.woff2') format('woff2'),
      url('@{fa-font-path}/subset-BrandonGrotesque-Bold.woff') format('woff'),
      url('@{fa-font-path}/subset-BrandonGrotesque-Bold.ttf') format('truetype'),
      url('@{fa-font-path}/subset-BrandonGrotesque-Bold.svg#BrandonGrotesque-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Brandon Grotesque';
  src: url('@{fa-font-path}/subset-BrandonGrotesque-Light.eot');
  src: url('@{fa-font-path}/subset-BrandonGrotesque-Light.eot?#iefix') format('embedded-opentype'),
      url('@{fa-font-path}/subset-BrandonGrotesque-Light.woff2') format('woff2'),
      url('@{fa-font-path}/subset-BrandonGrotesque-Light.woff') format('woff'),
      url('@{fa-font-path}/subset-BrandonGrotesque-Light.ttf') format('truetype'),
      url('@{fa-font-path}/subset-BrandonGrotesque-Light.svg#BrandonGrotesque-Light') format('svg');
  font-weight: 300;
  font-style: normal;
}
