
html.halloween-html,
body.halloween-body{
  background-color: @black;
  color: @white;
  overflow-x: hidden;
  scroll-behavior: smooth;
  p{
    color: #c5c5c5;
  }
  a,p,h1,h2,h3,h4,h5,img,div,footer,h6,span,section{
    &:focus{
      outline: none;
    }
  }
  @media (min-width:1541px) {
    font-size: 16px;
  }
  @media (max-width:1440px) {
    font-size: 12px;
  }
  @media (max-width:1280px) {
    font-size: 12px;
  }
  @media (max-width:1024px) {
    font-size: 13px;
  }
  @media @mobile{
    overflow-x: hidden;
  }
}
.donate-floating-bottom{
  top: 27%;
  position: fixed;
  right: -2px;
  .width(200);
  .height(225);
  display: block;
  overflow: hidden;
  z-index: 25;
  @media screen and (max-width: 1640px){
    .width(150);
    .height(170);
  }
  @media @mobile{
    .width(100);
    .height(112);
    bottom: 1rem;
    top: unset;
    transform: none;
  }
  &:hover{
    .coin{
      transform: translateY(18%);
    }
  }
  .bg-1{
    position: absolute;
    width: 100%;
    z-index: 1;
    top: 0;
    left: 0;
  }
  .bg-2{
    position: absolute;
    width: 100%;
    z-index: 3;
    top: 0;
    left: 0;
  }
  .coin{
    position: absolute;
    width: 100%;
    z-index: 2;
    top: 0;
    left: 0;
    .transition(all 0.5s ease);
  }
}
.halloween-images-section{

  .slick-dots-container {
    overflow: hidden;
    display: block;
    position: absolute;
    right: 2rem;
    bottom: 2rem;
    .width(200);
    padding: 0;
    margin: 0.625rem auto;
    height: 2.5rem;
    ul {
      padding: 0;
      display: flex;
      transition: all 0.25s;
      position: relative;
      margin: 0;
      list-style: none;
      transform: translateX(0);
      align-items: center;
      bottom: unset;
      height: 100%;
      li {
        width: 2rem;
        height: 2.5rem;
        margin: 0 0.25rem;
        border: none;
        border-radius: 50%;
        @media only screen and (max-width: 400px){
          .margin(0,8,0,0);
        }
        button {
          border: 0;
          width: 2rem;
          height: 2.5rem;
          padding: 0;
          overflow: hidden;
          text-indent: -999px;
          border-radius: 50%;
          flex-shrink: 0;
          background: transparent;
          background-image: url("../img/halloween/skulls.png");

          background-position: 50% 100%;
          background-size: 100% auto;
          @media @mobile {
            .width(8);
            .height(8);
          }
        }
        &.slick-active{
          button {
            background-position: 50% 0;
          }
        }
      }
    }

  }

  .halloween-pics-slider{
    background-color: @black;
    .item{
      background-color: @black;
      img{
        width: 100%;
        height: calc(100vh - 7.5rem);
        object-fit: cover;
        @media @tablet,@mobile{
          height: 56vw;
        }
      }
    }
    .slick-prev,
    .slick-next {
      position: absolute;
      .pos-centerY;
      .width(182);
      .height(135);
      cursor: pointer;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      z-index: 200;
      border: 0;
      background-color: transparent;
      background-image: url(../img/halloween/next-finger.png);
      @media @mobile{
        .width(91);
        .height(70);
      }

    }
    .slick-prev{
      left: 2.5rem;
      background-image: url(../img/halloween/prev-finger.png);
      @media @mobile{
        left: 1rem;
      }
      &:hover{
        left: 1.75rem;
        @media @mobile{
          left:0.5rem;
        }
      }
    }
    .slick-next{
      right: 2.5rem;
      @media @mobile{
        right:1rem;
      }
      &:hover{
        right: 1.75rem;
        @media @mobile{
          right:0.5rem;
        }
      }
    }
  }
}

.halloween{
  &-second-section{
    position: relative;
    z-index: 4;
    background-color: black;
    min-height: rem(620px);
    @media @tablet-portrait,@mobile{
      min-height: unset;
    }
    .container{
      max-width: 101.25rem;
      @media @tablet,@mobile{
        max-width: 90%;
        padding: 0 1.5rem;
      }
    }
    .images-wrap{
      position: relative;
      width: 100vw;
      //padding-top: 2rem;
      .clown{
        position: absolute;
        bottom: 0;
        left: 0;
        width: 45vw;
        object-fit: contain;
        z-index: 1;
        max-height: 100%;
        display: none;
        @media @tablet-portrait,@mobile{
          display: block;
          position: relative;
          width: 100vw;
          margin: 0 auto 3rem;
        }
      }
      .smoke-2{
        position: absolute;
        width: 44vw;
        z-index: 0;
        left: 8vw;
        bottom: 18vw;
        display: none;
        animation: animation-smoke-4 10s linear infinite;
        @media @tablet-portrait,@mobile{
          display: block;
          top: 0;
          width: 70vw;
          left: 36vw;
        }
      }
    }
    .clown-wrap{
      .padding(70,0,60,0);
      background-size: contain;
      background-position: left bottom;
      background-repeat: no-repeat;
      background-attachment: fixed;
      @media @tablet-portrait,@mobile{
        background-image: none!important;
        .padding(8,0,0,0);

      }
      .text-side{
        padding-left: 4rem;
        @media @tablet-portrait,@mobile{
          padding-left: 0;
        }
      }
    }

    h2{
      .font-size(40);
      .line-height(40);
      letter-spacing: 0.1em;
      margin-bottom: 2.5rem;
      text-transform: none;
      font-weight: 400;
      font-family: 'Riky Vampdator Normal';
      color: @red;
    }
    p{
      font-family: 'Athelas';

      .font-size(20);
      .line-height(36);
      letter-spacing: 0.1em;
      padding-bottom: 1.25rem;
    }
  }
  &-last-section{
    background-color: black;
    position: relative;
    .padding(94,0,327,0);
    overflow: hidden;
    z-index: 4;
    @media @mobile{
      .padding(50,0,450,0);
    }
    @media @mobile-xs{
      .padding(50,0,320,0);
    }
    .text{
      max-width:54.27rem;
      margin: 0 auto 4rem;
      position: relative;
      z-index: 7;
      @media @tablet-portrait,@mobile{
        max-width: 100%;
        margin: 0 auto 3rem;
      }
    }
    h2{
     margin: 0 -1rem;
      font-family: 'Riky Vampdator Normal';
      .font-size(60);
      .line-height(60);
      color: @red;
      letter-spacing: 0.1em;
      margin-bottom: 2rem;
      text-transform: none;
      font-weight: 400;
      text-align: center;
      @media @mobile{
        margin: 0 auto 3rem;
        .font-size(40);
        .line-height(45);
      }
    }
    .white-ttl{
      font-family: 'Riky Vampdator Normal';
      color: @red;
      .font-size(30);
      .line-height(40);
      letter-spacing: 0.1em;
      margin-bottom: 1.5rem;
    }
    p{
      font-family: 'Athelas';
      .font-size(24);
      .line-height(40);
      letter-spacing: 0.1em;
      text-align: center;
      margin-bottom: 0;
      color: #c5c5c5;
      @media @mobile{
        .font-size(20);
        .line-height(30);
      }
    }
    .btns-wrap{
     // display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      a{
        margin-bottom: 2rem;
      }
      .each-btn{
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
    .container{
      z-index: 5;
    }

    .pers-wrap{
      position: absolute;
      width: 100vw;
      max-height: 100%;
      z-index: 1;
      left: 0;
      bottom: 0;
      animation: animation-line-1 7s infinite ease;
      @media @mobile{
        display: none;
      }
      .pers-1{
       width: 100%;
        display: block;
        object-fit: contain;
        height: 100%;
      }
    }

    .smoke-2{
      position: absolute;
      width: 49.73vw;
      z-index: 2;
      left: 0;
      bottom: 0;
      animation: animation-smoke-3 10s linear infinite;
    }
    .bg-img{
      position: absolute;
      width: 100vw;
      z-index: 2;
      left: 0;
      bottom: 0;
      @media @mobile{
        width: 200vw;
        left: -99%;
      }
    }
    .bg-img-2{
      position: absolute;
      width: 100vw;
      z-index: 3;
      left: 0;
      bottom: 0;
      animation: animation-bg 10s linear infinite;
      @media @mobile{
        width: 200vw;
        left: -99%;
      }
    }
    .smoke{
      position: absolute;
      width: 100vw;
      z-index: 4;
      left: 0;
      bottom: 0;
      animation: animation-smoke-2 10s linear infinite;
      @media @mobile{
        width: 200vw;
        left: -99%;
      }
    }
    .ticket-wrap{
      position: absolute;
      z-index: 2;
      width: 12.18vw;
      bottom: 2vw;
      right: 16.77vw;
      animation: animation-ticket 8s infinite ease;
      transform-origin: 50% 100% ;
      @media @mobile{
        width: 26.18vw;
        right: unset;
        left: 19vw;
        bottom: 9vw;
      }
      .ticket{
        width: 100%;
        display: block;
      }
    }

  }

  &-first-section{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
    position: relative;
    @media @tablet-portrait,@mobile{
      height: auto!important;
    }
    .halloween-banner-video {

     // position: absolute;
      position: fixed;
      left: 0;
      width: 100%;
      padding-bottom: 31.5%;
      overflow: hidden;
      top: 0;
      z-index: 0;
      //@media @tablet{
      //  position: relative;
      //  margin-top: 2rem;
      //  width: 100vw;
      //  padding-bottom: 31.5%;
      //}
      @media @tablet-portrait,@mobile{
        position: relative;
        margin-top: 2rem;
        width: 100vw;
        padding-bottom: 38.5%;
      }
      &:before{
        content: "";
        position: absolute;
        width: 100vw;
        height: 26vw;
        bottom: -1px;
        object-fit: cover;
        z-index: 2;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 50% 100%;
        background-image: url("../img/halloween/shadow-bg-2.png");
      }
      video {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center top;
      }
    }
    .date{
      color: white;
      font-family: 'Libre Baskerville', serif;
      .font-size(25);
      .line-height(32);
      text-shadow: 0.877px 1.798px 1px rgba(0, 0, 0, 0.79);
      margin-top: 2.25rem;
      position: relative;
      z-index: 18;
      padding: 0.5rem 3.5rem;
      display: block;
      text-align: center;
      @media @mobile{
        .font-size(20);
        .line-height(25);
      }
      span{

        position: relative;
        z-index: 19;
      }
      &:before{
        content: "";
        position: absolute;
        height: 100%;
        width: 100%;
        background-size: 100% 100%;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        .pos-center;
        background-image: url("../img/halloween/brush2.png");
        z-index: 1;
      }
    }
    .text-content{
      width: 100vw;
      position: relative;
      z-index: 15;
      .text-content-inner{
        position: relative;
        z-index: 16;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        max-width: 95%;
        margin: 0 auto;
        @media @mobile{
          margin-top: -1rem;
        }
      }
    }
    h2{
      font-family: 'Riky Vampdator Normal';
      .font-size(40);
      .line-height(50);
      color: @red;
      letter-spacing: 0.1em;
      text-transform: none;
      font-weight: 400;
      text-align: center;
      @media @tablet{

        .font-size(35);
        .line-height(40);
      }
      @media @mobile{
        .font-size(30);
        .line-height(30);
      }

      span{
        display: block;
        margin-top: 1rem;
        .font-size(90);
        .line-height(90);
        @media @tablet{
          .font-size(60);
          .line-height(65);
        }
        @media @mobile{
          .font-size(50);
          .line-height(50);
        }
        @media @mobile-xs{
          .font-size(40);
          .line-height(45);
        }
      }
    }
    h3{
      font-family: 'Riky Vampdator Normal';
      .font-size(24);
      .line-height(30);
      color: @red;
      letter-spacing: 0.1em;
      margin-bottom: 0.625rem;
      text-transform: none;
      font-weight: 400;
      text-align: center;
      @media @tablet{
        margin: 0 auto 0.625rem;
        .font-size(22);
        .line-height(30);
      }
      @media @mobile{
        margin: 0 auto 0.625rem;
        .font-size(20);
        .line-height(30);
      }

    }
    p{
      //font-family: 'Riky Vampdator Normal';
      //.font-size(18);
      //.line-height(28);
      //color: @red;
      //letter-spacing: 0.1em;
      margin-bottom: 0.25rem;
      text-align: center;
     //font-family: 'Athelas';
      font-family: Libre Baskerville,serif;

      .font-size(20);
      .line-height(36);
      letter-spacing: 0.1em;

    }
    .ttl-img{
      display: block;
      margin: 0 auto 1rem;
      width: 100%;
      max-width: 52rem;
      max-height: 13vh;
      object-fit: contain;
      @media @tablet-portrait,@mobile{
        max-width: 80%;
      }
    }
    .scroll-arrow{
      display: block;
      margin-bottom: 0.5rem;
      cursor: pointer;
      width: 2rem;
      margin-top: 2.5rem;
      @media @mobile{
        margin-top: 1rem;
        margin-bottom: 0;
      }
      img{
        width: 2rem;
        @media @mobile{
          width: 1.5rem;
        }
      }
    }
    .images-block{
      position: absolute;
      top: 0;
      .pos-centerX;
      .width(1275);
      max-height: 62rem;
      @media @desktop-extra-big{
      .pos-center;
      }
      @media @tablet,@mobile{
        position: relative;
        margin-top: 2rem;
        width: 100vw;
        padding-bottom: 65%;
      }

    }


    .bottom-shadow{
      position: absolute;
      min-width: 100vw;
     // height: 190%;
      bottom: -1px;
      object-fit: cover;
      z-index: 12;
      @media @mobile{
        .height(260);
        width: 100vw;
      }
    }
    .shine{
      position: absolute;
      width: 100vw;
      top: 0;
      object-fit: cover;
      .pos-centerX;
      z-index: 1;
      height: calc(100vh - 162px);
     animation: animation-shine 14s linear infinite;
    }
    .death-wrap{
      width: 47%;
      position: absolute;
      left: -9%;
      top: 1rem;
      z-index: 10;
      animation: animation-death 10s infinite ease;
      @media @tablet,@mobile{
        left: 0rem;
        width: 40%;
      }
    }
    .death-pic{
      width: 100%;
    }
    .smoke-1{
      position: absolute;
      width: 31.6%;
      top: 0;
      right: 0;
      z-index: 1;
      animation: animation-smoke-4 10s linear infinite;
    }
    .smoke-2{
      position: absolute;
      width: 16%;
      top: 10%;
      left: 37%;
      z-index: 1;
      animation: animation-smoke-5 30s linear infinite;
    }
    .smoke-left{
      position: absolute;
      top: 2%;
      left: -19%;
      width: 75%;
      z-index: 2;
    //  animation: animation-smoke-3 10s linear infinite;
    }
    .smoke-right{
      position: absolute;
      width: 75%;
      bottom: 3%;
      right: -15%;
      z-index: 2;
      animation: animation-smoke-4 10s linear infinite;
    }

    .clown-2{
      position: absolute;
      width: 100%;
      max-height: 90%;
      bottom: 0;
      object-fit: contain;
      object-position: center top;
      left: 0;
      z-index: 2;
    //  opacity: 0.43;
    }
    .clown-top-2{
      position: absolute;
      width: 100%;
      max-height: 90%;
      bottom: 0;
      object-fit: contain;
      object-position: center top;
      left: 0;
      z-index: 17;
    }
    .door-2{
      position: absolute;
      width: 104%;
      max-height: 90%;
      bottom: 0;
      object-fit: contain;
      object-position: center top;
      left: 0;
      z-index: 3;
    }
    .door-1{
      position: absolute;
      width: 100%;
      max-height: 90%;
      bottom: 0;
      object-fit: contain;
      object-position: center top;
      left: 0;
      z-index: 10;
      transform-origin: 50% 100%;
    animation: animation-door 6s linear infinite;
    }
    .fly-woman-wrap{
      position: absolute;
      width: 44%;
      max-height: 80vh;
      transform-origin: 50% 0;
      animation: animation-fly-woman 16s linear infinite;
      right: 10%;
      z-index: 7;
      top: 0;
    }
    .fly-woman{
      width: 100%;
    }
  }
}

.donate-red-btn{
  display: block;
  width: max-content;
  margin: 0 auto;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  white-space: nowrap;
  font-family: 'Riky Vampdator Normal';
  .font-size(30);
  .line-height(66);
  color: @white;
  letter-spacing: 0.2em;
  text-align: center;
  padding: 0 2rem;
  .transition(all 0.3s ease);
  @media @mobile{
    .font-size(20);
    .line-height(42);
  }
  &:hover{
    color: @white;
    text-decoration: none;
    transform: scale(1.07);
  }
}
.buy-btn{
  display: block;
  width: max-content;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  white-space: nowrap;
  font-family: 'Riky Vampdator Normal';
  .font-size(30);
  .line-height(67);
  color: @white;
  letter-spacing: 0.2em;
  text-align: center;
  margin: 0 auto ;
  padding: 0 2rem;
  .transition(all 0.3s ease);

  @media @mobile{
    .font-size(20);
    .line-height(42);
    margin: 0 auto ;
  }
  &:hover{
    color: @white;
    text-decoration: none;
    transform: scale(1.07);
  }
}

@keyframes animation-line-1 {
  0% {
    transform: translate(0,0);
  }
  50% {
    transform: translate(0%,1.25rem);
  }
  100% {
    transform: translate(0,0);
  }
}
@-webkit-keyframes animation-line-1 {
  0% {
    transform: translate(0,0);
  }
  50% {
    transform: translate(0%,1.25rem);
  }
  100% {
    transform: translate(0,0);
  }
}

@keyframes animation-ticket {
  0% {
    transform: translate(0,0);
  }
  50% {
    transform: rotate(-7deg);
  }
  100% {
    transform: translate(0,0);
  }
}
@-webkit-keyframes animation-ticket {
  0% {
    transform: translate(0,0);
  }
  50% {
    transform: rotate(-7deg);
  }
  100% {
    transform: translate(0,0);
  }
}

@keyframes animation-bg {
  0% {
    transform: translate(0,0) scale(1);
  }
  50% {
    transform:  translate(-10%,-1rem ) scale(1.3);
  }
  100% {
    transform: translate(0,0) scale(1);
  }
}

@-webkit-keyframes animation-bg {
  0% {
    transform: translate(0,0) scale(1);
  }
  50% {
    transform:  translate(-10%,-1rem ) scale(1.3);
  }
  100% {
    transform: translate(0,0) scale(1);
  }
}

@keyframes animation-smoke-2 {
  0% {
    transform: translate(0,0) rotateX(0deg) rotateY(0deg) skew(0);
  }
  50% {
    transform:  translate(0%,-5% ) rotateX(-40deg) rotateY(-10deg) skewX(-20deg);
  }
  100% {
    transform: translate(0,0) rotateX(0deg) skew(0) rotateY(0deg);
  }
}
@-webkit-keyframes animation-smoke-2 {
  0% {
    transform: translate(0,0) rotateX(0deg) rotateY(0deg) skew(0);
  }
  50% {
    transform:  translate(0%,-5% ) rotateX(-40deg) rotateY(-10deg) skewX(-20deg);
  }
  100% {
    transform: translate(0,0) rotateX(0deg) skew(0) rotateY(0deg);
  }
}
@keyframes animation-smoke-3 {
  0% {
    transform: translate(0,0) rotateX(0deg) rotateY(0deg) skew(0);
  }
  50% {
    transform:  translate(0%,-2% ) rotateX(-40deg) rotateY(-10deg) skewX(-20deg);
  }
  100% {
    transform: translate(0,0) rotateX(0deg) skew(0) rotateY(0deg);
  }
}

@-webkit-keyframes animation-smoke-3 {
  0% {
    transform: translate(0,0) rotateX(0deg) rotateY(0deg) skew(0);
  }
  50% {
    transform:  translate(0%,-2% ) rotateX(-40deg) rotateY(-10deg) skewX(-20deg);
  }
  100% {
    transform: translate(0,0) rotateX(0deg) skew(0) rotateY(0deg);
  }
}

@keyframes animation-smoke-4 {
  0% {
    transform: translate(0,0) rotateY(0deg) rotateY(0deg) skew(0);
  }
  50% {
    transform:  translate(0%,-2% ) rotateY(-5deg) rotateY(-20deg) skewX(-7deg);
  }
  100% {
    transform: translate(0,0) rotateY(0deg) skew(0) rotateY(0deg);
  }
}
@-webkit-keyframes animation-smoke-4 {
  0% {
    transform: translate(0,0) rotateY(0deg) rotateY(0deg) skew(0);
  }
  50% {
    transform:  translate(0%,-2% ) rotateY(-5deg) rotateY(-20deg) skewX(-7deg);
  }
  100% {
    transform: translate(0,0) rotateY(0deg) skew(0) rotateY(0deg);
  }
}

@keyframes animation-smoke-5 {
  0% {
    transform: rotate(0deg) skew(0);
  }
  50% {
    transform:   rotate(-180deg) skewX(-10deg);
  }
  100% {
    transform: rotate(0deg) skew(0);
  }
}
@-webkit-keyframes animation-smoke-5 {
  0% {
    transform: rotate(0deg) skew(0);
  }
  50% {
    transform:   rotate(-180deg) skewX(-10deg);
  }
  100% {
    transform: rotate(0deg) skew(0);
  }
}

@keyframes animation-shine {
  0% {
    transform: translate(-50%,0) scale(1) skew(deg);
  }
  50% {
     transform:  translate(-50%,-1rem ) scale(1.1) skew(2deg);
   }
  75% {
    transform:  translate(-50%,1rem ) scale(1.1) skew(-2deg);
  }
  100% {
    transform: translate(-50%,0) scale(1) skew(0deg);
  }
}




@keyframes animation-death{
  0% {
    transform: translate(0,0) skew(0);
  }
  50% {
    transform: translate(0%, -1rem) skewX(-4deg);
  }
  100% {
    transform: translate(0,0) skew(0);
  }
}

@-webkit-keyframes animation-death{
  0% {
    transform: translate(0,0) skew(0);
  }
  50% {
    transform: translate(0%, -1rem) skewX(-4deg);
  }
  100% {
    transform: translate(0,0) skew(0);
  }
}


@keyframes animation-fly-woman{

  0% {
    transform: translate(0,0) rotate(0);
  }

  40% {
    transform: translate(0%,-2%)  rotate(-15deg);
  }
  80% {
    transform: translate(0,0) rotate(5deg);
  }
  0% {
    transform: translate(0,0) rotate(0);
  }
}
@-webkit-keyframes animation-fly-woman{

  0% {
    transform: translate(0,0) rotate(0);
  }

  40% {
    transform: translate(0%,-2%)  rotate(-15deg);
  }
  80% {
    transform: translate(0,0) rotate(5deg);
  }
  0% {
    transform: translate(0,0) rotate(0);
  }
}
@keyframes animation-door{

  0% {
    transform: translate(0,0) rotate(0);
  }


  80% {
    transform: translate(0,0) rotateY(16deg);
  }
  0% {
    transform: translate(0,0) rotate(0);
  }
}