.contact-page {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  @media @tablet, @mobile {
    margin: 0;
  }
  @media @tablet-portrait, @mobile {
    flex-direction: column;
    align-items: center;
  }
  .contact {
    .padding(65,0,0,0);
    position: relative;
    @media @tablet-portrait, @mobile {
      padding: 1rem 0 3rem;
    }
    .ttl{
      .font-size(18);
      .MB;
      .line-height(25);
      color:@primary;
      text-transform: none;
      margin-bottom: 0.9rem;
    }
    dl {
      .margin(0,0,10,0);
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      .line-height(25);
      .MR;
      dt {
        color: @text;
        .width(33);
        .font-size(15);
        i{
          color: @primary;
        }
      }
      dd {
        margin: 0;
        color:@text;
        .font-size(15);
        a {
          color:@text;
          .line-height(25);
          .MR;
        }
      }
    }
  }
  .form-holder {
    background: @white;
    .width(850);
    flex-shrink: 0;
    margin-top: -2rem;
    box-shadow: 1.777px 16.907px 68px 0px rgba(0, 0, 0, 0.2);
    .padding(0,50,30,50);
    border-radius: 20px 0 20px 20px;
    z-index: 5;
    @media @tablet{
      margin-top: 3rem;
      margin-bottom: 3rem;
    }
    @media @mobile {
      margin-top: 3rem;
      margin-bottom: 3rem;
      width:90%;
    }
    @media @mobile-xs {
      width:100%;
      padding: 0 2rem;
    }
    .logo{
      margin: 0 auto;
      .circle(8.3125rem);
      margin-top: -4.17rem;
      margin-bottom: 0.375rem;
      background-color: @white;
      background-size: 6.6875rem auto;
      background-position: center;
      background-repeat: no-repeat;
      animation: pulse-icon-enlarge-1 4s ease-in-out infinite;
    }
    .ttl {
      .text-center;
      .margin(0,0,35,0);
      .form-ttl{
        color: @primary;
        .font-size(24);
        .line-height(40);
        .margin(0,0,15,0);
        .MB;
        text-transform: none;
        .margin(0,0,13,0);
      }
      .form-sub-ttl{
        color: @text;
        .font-size(16);
        .line-height(26);
        .MR;
        text-transform: none;
        .margin(0,0,13,0);
      }
    }
    .btn-wrap{
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-bottom: 2rem;
      @media @mobile{
        justify-content: center;
        .btn{
          width: 100%;
        }
      }
    }
  }
 
}