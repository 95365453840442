.footer {
  background: @primary;
  color:@white;
  .font-size(16);
  .MB;
  .line-height(25);
  letter-spacing: 0.02em;
  .padding(40,0,0,0);
  position: relative;
  overflow: hidden;
  @media @mobile{
    .padding(40,0,10,0);
  }
  &.footer--black{
    background-color: rgb(0, 0, 0);
    border-top: 1px solid @red;
    .social {

      a {

        color:@white;
        background-color: transparent;
        border: 1px solid @white;

        &:hover {
          text-decoration: none;
          cursor: pointer;
          border: 1px solid @red;
          background-color: transparent;
          color:@red;
        }
        i {
          .font-size(17);
        }
      }
    }
  }
  .fd-left-top{
    position: absolute;
    .width(344);
    height: auto;
    top: 0;
    left:0;
    @media @mobile {
      .width(250);
    }
  }
  
  .fd-left-bot{
    position: absolute;
    .width(595);
    height: auto;
    bottom: 0;
    left:0;
    @media @mobile {
      .width(200);
      left: -7rem;
    }
  }
  .fd-right-top{
    position: absolute;
    .width(307);
    height: auto;
    top: 0;
    right: 0;
    @media @mobile {
      .width(128);
      
    }
  }
  .fd-right-bot{
    position: absolute;
    .width(214);
    height: auto;
    bottom: 0;
    right: 0;
    @media @mobile {
      .width(118);
      
    }
  }
 p{
   .font-size(14);
   color: @white;
   .MR;
   .line-height(30);
   margin-bottom: 0.7rem;
   text-align: left;
   @media @mobile{
     text-align: center;
   }
 }
 h5{
   .MB;
   .font-size(16);
   .line-height(25);
   color: @white;
   text-align: left;
   margin-bottom: 0.5rem;
   @media @mobile{
     text-align: center;
   }
 }
 .email-form{
  .padding(0,30,20,0);
  @media @mobile{
    .padding(20,0,20,0);
  }
  .menu__search{
    position: relative;
    .email_input {
    min-width: 16.25rem;
    color: @white;
    padding: .375rem .625rem .375rem 0.1rem;
    width: 100%;
    font-size: .875rem;
    display: block;
    width: 100%;
    height: calc(3rem + 2px);
    .font-size(13);
    .MR;
    color: @white;
    background-color: transparent;
    background-clip: padding-box;
    border: 0;
    border-bottom: 1px solid @white;
    border-radius: 0;
    &::placeholder{
      color: @white;
      .font-size(13);
      .MR;
    }
    &:focus{
      outline: 1px dashed @white;
    }
    }
  .email_submit {
    display: block;
    right: 0;
    padding: 0;
    position: absolute;
    bottom:  0;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    width: 1.5rem;
    height: 3rem;
    border: 0;
    background-color: transparent;
    &:focus{
      outline: 1px dashed @white;
    }
    &:before {
      content: "\f054";
      position: absolute;
      .pos-centerY;
      right: 0.4rem;
      font-family: Font Awesome\ 5 Free;
      font-weight: 900;
      color: @white;
    }
    .hide-content {
      border: 0;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
      }
    }
  }
  }
  a {
    color:@white;
  }
  .copy{
    .font-size(14);
    text-align: left;
    .MR;
    .line-height(24);
    @media @mobile{
      text-align: center;
    }
  }
  .social {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    z-index: 5;
    text-align: center;
    .margin(22,0,30,0);
    @media @mobile {
      justify-content: center;
      .margin(13,0,36,0);
    }
    a {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      color:@white;
      .width(36);
      .height(36);
      border-radius: 50%;
      background-color: @primary;
      border: 1px solid @white;
      transition: 0.2s ease-in-out;
      .margin(0,0,0,24);
      @media @mobile{
        .margin(0,12,0,12);
      }
      &:hover {
        text-decoration: none;
        cursor: pointer;
        background-color: @white;
        color:@primary;
      }
      i {
        .font-size(17);
      }
    }
  }
  .logo {
    .width(119);
    height: auto;
    @media @tablet-portrait{
      .width(90);
    }
    @media @mobile {
      .width(120);
      margin-bottom: 2rem;
    }
  }
  a,p,h1,h2,h3,h4,h5,img,div{
    &:focus{
      outline: 1px dashed @white;
    }
    }
}

.cookie-block {
  position: fixed;
  left:0;
  bottom: 0;
  width:100%;
  background: fade(@black, 90%);
  .padding(16,0,16,0);
  z-index: 999;
  .inner {
    display: flex;
    justify-content: center;
    align-items: center;
    @media @mobile-xs {
      flex-direction: column;
    }
    .text {
      text-align: center;
      color:@white;
      .font-size(16);
      .line-height(32);
      .padding(0,40,0,0);
      @media @mobile-xs {
        .font-size(15);
        .line-height(20);
        .padding(0,0,16,0);
      }
      h6 {
        .font-size(18);
        .margin(0,0,5,0);
        font-weight: 700;
      }
      p {
        margin: 0;
        color: @white;
      }
    }
   
  }
  
}

