@font-face {
  font-family: 'Athelas';
  src: url('../fonts/Athelas-Regular.eot');
  src: url('../fonts/Athelas-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Athelas-Regular.woff2') format('woff2'),
  url('../fonts/Athelas-Regular.woff') format('woff'),
  url('../fonts/Athelas-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Riky Vampdator Normal';
  src: url('../fonts/RikyVampdatorNormal.eot');
  src: url('../fonts/RikyVampdatorNormal.eot?#iefix') format('embedded-opentype'),
  url('../fonts/RikyVampdatorNormal.woff2') format('woff2'),
  url('../fonts/RikyVampdatorNormal.woff') format('woff'),
  url('../fonts/RikyVampdatorNormal.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

